/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/

@import 'topbar-mail';
@import 'comments';
@import 'chat-widgets';
@import 'chat-popup';
@import 'steamline';
@import 'feeds';
@import 'task';
@import 'profile';