.navbar-light {
    box-shadow: 0 2px 2px rgb(0 0 0 / 5%);

    .text-light {
        color: rgba(17, 43, 74, 0.84) !important;
        font-weight: 600;
    }

    .light-logo {
        width: 130px;
    }
}

.bg-light {
    background-color: unset !important;
}

.step {
    list-style: none;
    margin: 40px auto 20px;
    width: 50%;
    padding: 0;

    .step-item {
        flex: 1 1 0;
        margin-top: 0;
        min-height: 1rem;
        position: relative;
        text-align: center;

        a {
            color: #acb3c2;
            display: inline-block;
            padding: 20px 10px 0;
            text-decoration: none;

            &::before {
                background: #0069d9;
                border: 0.1rem solid $white;
                border-radius: 50%;
                content: "";
                display: block;
                height: 0.9rem;
                left: 50%;
                position: absolute;
                top: 0.2rem;
                transform: translateX(-50%);
                width: 0.9rem;
                z-index: 1;
            }
        }

        &:not(:first-child)::before {
            background: $blue-100;
            content: "";
            height: 2.5px;
            left: -50%;
            position: absolute;
            top: 9px;
            width: 100%;
        }
    }

    .step-item.active {
        a::before {
            background: $white;
            border: 0.1rem solid $blue-100;
        }

        ~ .step-item::before {
            background: $gray-1000;
        }

        ~ .step-item a::before {
            background: $gray-1000;
        }
    }
}

.step-img {
    width: 80%;
    vertical-align: middle;
}
