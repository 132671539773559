﻿@import "./modern/constants.scss";
@import "./modern/blocks/sv-dragdrop.scss";
@import "./modern/blocks/sv-window.scss";
@import "./common-styles/sv-popup.scss";
@import "./common-styles/sv-progress-buttons.scss";
@import "./common-styles/sv-progress-toc.scss";
@import "./modern/blocks/sv-container.scss";
@import "./modern/blocks/sv-body.scss";
@import "./modern/blocks/sv-page.scss";
@import "./modern/blocks/sv-panel.scss";
@import "./modern/blocks/sv-paneldynamic.scss";
@import "./modern/blocks/sv-row.scss";
@import "./modern/blocks/sv-question.scss";
@import "./modern/blocks/sv-progress.scss";
@import "./modern/blocks/sv-title.scss";
@import "./modern/blocks/sv-description.scss";
@import "./modern/blocks/sv-selectbase.scss";
@import "./modern/blocks/sv-multipletext.scss";
@import "./modern/blocks/sv-matrix.scss";
@import "./modern/blocks/sv-matrixdynamic.scss";
@import "./modern/blocks/sv-scroll.scss";
@import "./modern/blocks/sv-table.scss";
@import "./modern/blocks/sv-footer.scss";
@import "./modern/blocks/sv-btn.scss";
@import "./modern/blocks/sv-item.scss";
@import "./modern/blocks/sv-checkbox.scss";
@import "./modern/blocks/sv-radio.scss";
@import "./modern/blocks/sv-boolean.scss";
@import "./modern/blocks/sv-imagepicker.scss";
@import "./modern/blocks/sv-dropdown.scss";
@import "./modern/blocks/sv-tagbox.scss";
@import "./modern/blocks/sv-text.scss";
@import "./modern/blocks/sv-rating.scss";
@import "./modern/blocks/sv-comment.scss";
@import "./modern/blocks/sv-file.scss";
@import "./modern/blocks/sv-clearfix.scss";
@import "./modern/blocks/sv-completedpage.scss";
@import "./modern/blocks/sv-header.scss";
@import "./modern/blocks/sv-signaturepad.scss";
@import "./common-styles/common-styles.scss";
@import "./signaturepad.scss";
@import "./boolean-checkbox.scss";
@import "./components-container.scss";

//@import "./main.rtl.scss";
@import "./modern.m600.scss";

body {
  --sv-modern-mark: true;
}

.sv-matrixdynamic__drag-icon {
  padding-top: 16px;
}
.sv-matrixdynamic__drag-icon:after {
  content: " ";
  display: block;
  height: 6px;
  width: 20px;
  border: 1px solid var(--border-color, $border-color);
  box-sizing: border-box;
  border-radius: 10px;
  cursor: move;
  margin-top: 12px;
}
.sv-matrix__drag-drop-ghost-position-top,
.sv-matrix__drag-drop-ghost-position-bottom {
  position: relative;
}
.sv-matrix__drag-drop-ghost-position-top::after,
.sv-matrix__drag-drop-ghost-position-bottom::after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: var(--main-color, $main-color);
  position: absolute;
  left: 0;
}
.sv-matrix__drag-drop-ghost-position-top::after {
  top: 0;
}
.sv-matrix__drag-drop-ghost-position-bottom::after {
  bottom: 0;
}

.sv-skeleton-element {
  background-color: var(--background-dim, $background-dim);
}
