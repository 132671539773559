.mdi-home {
    color: $black;
    font-size: 1.4rem;
}

ol.breadcrumb {
    padding-left: 0;
    padding-bottom: 0;
}

.list-group {
    border-radius: 5px;

    &-item.active > a {
        color: $white;
    }
}

.card {
    border: 1px solid #d5dde6;
    border-radius: 0.25rem;

    .form-control, .form-select {
        border: 1px solid #dbe5ee;
        border-radius: 0.25rem;
        box-shadow: inset 0 1px 2px 0 rgb(66 71 112 / 12%);
    }
}
