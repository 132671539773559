@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?19ual8');
  src:  url('fonts/icomoon.eot?19ual8#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?19ual8') format('truetype'),
    url('fonts/icomoon.woff?19ual8') format('woff'),
    url('fonts/icomoon.svg?19ual8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="brand-icon-"], [class*=" brand-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brand-icon-walmart:before {
  content: "\eca6";
  color: #fdbb30;
}
.brand-icon-1password:before {
  content: "\e900";
  color: #0094f5;
}
.brand-icon-500px:before {
  content: "\e901";
  color: #0099e5;
}
.brand-icon-abbrobotstudio:before {
  content: "\e902";
  color: #ff9e0f;
}
.brand-icon-about-dot-me:before {
  content: "\e903";
  color: #00a98f;
}
.brand-icon-abstract:before {
  content: "\e904";
}
.brand-icon-academia:before {
  content: "\e905";
}
.brand-icon-accusoft:before {
  content: "\e906";
  color: #ff7e4a;
}
.brand-icon-acm:before {
  content: "\e907";
  color: #0085ca;
}
.brand-icon-addthis:before {
  content: "\e908";
  color: #ff6550;
}
.brand-icon-adguard:before {
  content: "\e909";
  color: #66b574;
}
.brand-icon-adobe:before {
  content: "\e90a";
  color: #f00;
}
.brand-icon-adobeacrobatreader:before {
  content: "\e90b";
  color: #ee3f24;
}
.brand-icon-adobeaftereffects:before {
  content: "\e90c";
  color: #d291ff;
}
.brand-icon-adobeaudition:before {
  content: "\e90d";
  color: #00e4bb;
}
.brand-icon-adobecreativecloud:before {
  content: "\e90e";
  color: #d41818;
}
.brand-icon-adobedreamweaver:before {
  content: "\e90f";
  color: #35fa00;
}
.brand-icon-adobeillustrator:before {
  content: "\e910";
  color: #ff7c00;
}
.brand-icon-adobeindesign:before {
  content: "\e911";
  color: #fd3f93;
}
.brand-icon-adobelightroomcc:before {
  content: "\e912";
  color: #3df0f0;
}
.brand-icon-adobelightroomclassic:before {
  content: "\e913";
  color: #add5ec;
}
.brand-icon-adobephotoshop:before {
  content: "\e914";
  color: #00c8ff;
}
.brand-icon-adobepremiere:before {
  content: "\e915";
  color: #ea77ff;
}
.brand-icon-adobetypekit:before {
  content: "\e916";
  color: #87ec00;
}
.brand-icon-adobexd:before {
  content: "\e917";
  color: #ff2bc2;
}
.brand-icon-airbnb:before {
  content: "\e918";
  color: #ff5a5f;
}
.brand-icon-airplayaudio:before {
  content: "\e919";
}
.brand-icon-airplayvideo:before {
  content: "\e91a";
}
.brand-icon-algolia:before {
  content: "\e91b";
  color: #5468ff;
}
.brand-icon-alliedmodders:before {
  content: "\e91c";
  color: #1578d3;
}
.brand-icon-amazon:before {
  content: "\e91d";
  color: #f90;
}
.brand-icon-amazonalexa:before {
  content: "\e91e";
  color: #00caff;
}
.brand-icon-amazonaws:before {
  content: "\e91f";
}
.brand-icon-amd:before {
  content: "\e920";
  color: #ed1c24;
}
.brand-icon-americanexpress:before {
  content: "\e921";
  color: #2e77bc;
}
.brand-icon-anaconda:before {
  content: "\e922";
  color: #42b029;
}
.brand-icon-analogue:before {
  content: "\e923";
}
.brand-icon-anchor:before {
  content: "\e924";
  color: #8940fa;
}
.brand-icon-android:before {
  content: "\e925";
  color: #3ddc84;
}
.brand-icon-angellist:before {
  content: "\e926";
}
.brand-icon-angular:before {
  content: "\e927";
  color: #dd0031;
}
.brand-icon-angularuniversal:before {
  content: "\e928";
  color: #00acc1;
}
.brand-icon-ansible:before {
  content: "\e929";
  color: #e00;
}
.brand-icon-apache:before {
  content: "\e92a";
  color: #d22128;
}
.brand-icon-apacheairflow:before {
  content: "\e92b";
  color: #007a88;
}
.brand-icon-apachecordova:before {
  content: "\e92c";
  color: #e8e8e8;
}
.brand-icon-apacheflink:before {
  content: "\e92d";
  color: #e6526f;
}
.brand-icon-apachekafka:before {
  content: "\e92e";
}
.brand-icon-apachenetbeanside:before {
  content: "\e92f";
  color: #1b6ac6;
}
.brand-icon-apacheopenoffice:before {
  content: "\e930";
  color: #0e85cd;
}
.brand-icon-apacherocketmq:before {
  content: "\e931";
  color: #d77310;
}
.brand-icon-apachespark:before {
  content: "\e932";
  color: #e25a1c;
}
.brand-icon-apple:before {
  content: "\e933";
  color: #999;
}
.brand-icon-applemusic:before {
  content: "\e934";
}
.brand-icon-applepay:before {
  content: "\e935";
}
.brand-icon-applepodcasts:before {
  content: "\e936";
  color: #93c;
}
.brand-icon-appveyor:before {
  content: "\e937";
  color: #00b3e0;
}
.brand-icon-aral:before {
  content: "\e938";
  color: #0063cb;
}
.brand-icon-archiveofourown:before {
  content: "\e939";
  color: #900;
}
.brand-icon-archlinux:before {
  content: "\e93a";
  color: #1793d1;
}
.brand-icon-arduino:before {
  content: "\e93b";
  color: #00979d;
}
.brand-icon-artstation:before {
  content: "\e93c";
  color: #13aff0;
}
.brand-icon-arxiv:before {
  content: "\e93d";
  color: #b31b1b;
}
.brand-icon-asana:before {
  content: "\e93e";
}
.brand-icon-asciidoctor:before {
  content: "\e93f";
  color: #e40046;
}
.brand-icon-at-and-t:before {
  content: "\e940";
  color: #00a8e0;
}
.brand-icon-atlassian:before {
  content: "\e941";
  color: #0052cc;
}
.brand-icon-atom:before {
  content: "\e942";
  color: #66595c;
}
.brand-icon-audi:before {
  content: "\e943";
  color: #bb0a30;
}
.brand-icon-audible:before {
  content: "\e944";
  color: #f8991c;
}
.brand-icon-aurelia:before {
  content: "\e945";
  color: #ed2b88;
}
.brand-icon-auth0:before {
  content: "\e946";
  color: #eb5424;
}
.brand-icon-automatic:before {
  content: "\e947";
  color: #7d8084;
}
.brand-icon-autotask:before {
  content: "\e948";
  color: #e51937;
}
.brand-icon-aventrix:before {
  content: "\e949";
  color: #09d;
}
.brand-icon-awesomewm:before {
  content: "\e94a";
  color: #535d6c;
}
.brand-icon-azureartifacts:before {
  content: "\e94b";
  color: #cb2e6d;
}
.brand-icon-azuredevops:before {
  content: "\e94c";
  color: #0078d7;
}
.brand-icon-azurepipelines:before {
  content: "\e94d";
  color: #2560e0;
}
.brand-icon-babel:before {
  content: "\e94e";
  color: #f9dc3e;
}
.brand-icon-baidu:before {
  content: "\e94f";
  color: #2319dc;
}
.brand-icon-bamboo:before {
  content: "\e950";
  color: #0052cc;
}
.brand-icon-bancontact:before {
  content: "\e951";
  color: #005498;
}
.brand-icon-bandcamp:before {
  content: "\e952";
  color: #408294;
}
.brand-icon-bandlab:before {
  content: "\e953";
  color: #dc3710;
}
.brand-icon-basecamp:before {
  content: "\e954";
  color: #5ecc62;
}
.brand-icon-bathasu:before {
  content: "\e955";
  color: #00a3e0;
}
.brand-icon-beats:before {
  content: "\e956";
  color: #005571;
}
.brand-icon-beatsbydre:before {
  content: "\e957";
  color: #e01f3d;
}
.brand-icon-behance:before {
  content: "\e958";
  color: #1769ff;
}
.brand-icon-bigcartel:before {
  content: "\e959";
}
.brand-icon-bing:before {
  content: "\e95a";
  color: #008373;
}
.brand-icon-bit:before {
  content: "\e95b";
  color: #73398d;
}
.brand-icon-bitbucket:before {
  content: "\e95c";
  color: #0052cc;
}
.brand-icon-bitcoin:before {
  content: "\e95d";
  color: #f7931a;
}
.brand-icon-bitdefender:before {
  content: "\e95e";
  color: #ed1c24;
}
.brand-icon-bitly:before {
  content: "\e95f";
  color: #ee6123;
}
.brand-icon-bitrise:before {
  content: "\e960";
  color: #683d87;
}
.brand-icon-blackberry:before {
  content: "\e961";
}
.brand-icon-blender:before {
  content: "\e962";
  color: #f5792a;
}
.brand-icon-blogger:before {
  content: "\e963";
  color: #ff5722;
}
.brand-icon-bmcsoftware:before {
  content: "\e964";
  color: #fe5000;
}
.brand-icon-boeing:before {
  content: "\e965";
  color: #1d439c;
}
.brand-icon-boost:before {
  content: "\e966";
  color: #f69220;
}
.brand-icon-bootstrap:before {
  content: "\e967";
  color: #563d7c;
}
.brand-icon-bower:before {
  content: "\e968";
  color: #ef5734;
}
.brand-icon-box:before {
  content: "\e969";
  color: #0061d5;
}
.brand-icon-brand-dot-ai:before {
  content: "\e96a";
  color: #0aa0ff;
}
.brand-icon-brandfolder:before {
  content: "\e96b";
  color: #40d1f5;
}
.brand-icon-brave:before {
  content: "\e96c";
  color: #fb542b;
}
.brand-icon-breaker:before {
  content: "\e96d";
  color: #003dad;
}
.brand-icon-broadcom:before {
  content: "\e96e";
  color: #cc092f;
}
.brand-icon-buddy:before {
  content: "\e96f";
  color: #1a86fd;
}
.brand-icon-buffer:before {
  content: "\e970";
  color: #168eea;
}
.brand-icon-buymeacoffee:before {
  content: "\e971";
  color: #ff813f;
}
.brand-icon-buzzfeed:before {
  content: "\e972";
  color: #e32;
}
.brand-icon-c:before {
  content: "\e973";
  color: #a8b9cc;
}
.brand-icon-cakephp:before {
  content: "\e974";
  color: #d33c43;
}
.brand-icon-campaignmonitor:before {
  content: "\e975";
  color: #509cf6;
}
.brand-icon-canva:before {
  content: "\e976";
  color: #00c4cc;
}
.brand-icon-cashapp:before {
  content: "\e977";
  color: #00c244;
}
.brand-icon-cassandra:before {
  content: "\e978";
  color: #1287b1;
}
.brand-icon-castorama:before {
  content: "\e979";
  color: #0078d7;
}
.brand-icon-castro:before {
  content: "\e97a";
  color: #00b265;
}
.brand-icon-centos:before {
  content: "\e97b";
  color: #262577;
}
.brand-icon-cevo:before {
  content: "\e97c";
  color: #1eabe2;
}
.brand-icon-chase:before {
  content: "\e97d";
  color: #117aca;
}
.brand-icon-chef:before {
  content: "\e97e";
  color: #f09820;
}
.brand-icon-circle:before {
  content: "\e97f";
  color: #8669ae;
}
.brand-icon-circleci:before {
  content: "\e980";
}
.brand-icon-cirrusci:before {
  content: "\e981";
}
.brand-icon-cisco:before {
  content: "\e982";
  color: #1ba0d7;
}
.brand-icon-civicrm:before {
  content: "\e983";
  color: #81c459;
}
.brand-icon-clockify:before {
  content: "\e984";
  color: #03a9f4;
}
.brand-icon-clojure:before {
  content: "\e985";
  color: #5881d8;
}
.brand-icon-cloudbees:before {
  content: "\e986";
  color: #1997b5;
}
.brand-icon-cloudflare:before {
  content: "\e987";
  color: #f38020;
}
.brand-icon-cmake:before {
  content: "\e988";
  color: #064f8c;
}
.brand-icon-co-op:before {
  content: "\e989";
  color: #00b1e7;
}
.brand-icon-codacy:before {
  content: "\e98a";
}
.brand-icon-codecademy:before {
  content: "\e98b";
  color: #1f4056;
}
.brand-icon-codechef:before {
  content: "\e98c";
  color: #5b4638;
}
.brand-icon-codeclimate:before {
  content: "\e98d";
}
.brand-icon-codecov:before {
  content: "\e98e";
  color: #f01f7a;
}
.brand-icon-codefactor:before {
  content: "\e98f";
  color: #f44a6a;
}
.brand-icon-codeforces:before {
  content: "\e990";
  color: #1f8acb;
}
.brand-icon-codeigniter:before {
  content: "\e991";
  color: #ee4623;
}
.brand-icon-codepen:before {
  content: "\e992";
}
.brand-icon-coderwall:before {
  content: "\e993";
  color: #3e8dcc;
}
.brand-icon-codesandbox:before {
  content: "\e994";
}
.brand-icon-codeship:before {
  content: "\e995";
  color: #3c4858;
}
.brand-icon-codewars:before {
  content: "\e996";
  color: #ad2c27;
}
.brand-icon-codio:before {
  content: "\e997";
  color: #4574e0;
}
.brand-icon-coffeescript:before {
  content: "\e998";
}
.brand-icon-coinbase:before {
  content: "\e999";
  color: #0667d0;
}
.brand-icon-commonworkflowlanguage:before {
  content: "\e99a";
  color: #b5314c;
}
.brand-icon-composer:before {
  content: "\e99b";
  color: #885630;
}
.brand-icon-compropago:before {
  content: "\e99c";
  color: #00aaef;
}
.brand-icon-conda-forge:before {
  content: "\e99d";
}
.brand-icon-conekta:before {
  content: "\e99e";
  color: #414959;
}
.brand-icon-confluence:before {
  content: "\e99f";
}
.brand-icon-coronarenderer:before {
  content: "\e9a0";
  color: #e6502a;
}
.brand-icon-coursera:before {
  content: "\e9a1";
  color: #2a73cc;
}
.brand-icon-coveralls:before {
  content: "\e9a2";
  color: #3f5767;
}
.brand-icon-cpanel:before {
  content: "\e9a3";
  color: #ff6c2c;
}
.brand-icon-cplusplus:before {
  content: "\e9a4";
  color: #00599c;
}
.brand-icon-creativecommons:before {
  content: "\e9a5";
  color: #ef9421;
}
.brand-icon-crehana:before {
  content: "\e9a6";
  color: #4b22f4;
}
.brand-icon-crunchbase:before {
  content: "\e9a7";
  color: #0288d1;
}
.brand-icon-crunchyroll:before {
  content: "\e9a8";
  color: #f47521;
}
.brand-icon-cryengine:before {
  content: "\e9a9";
}
.brand-icon-csharp:before {
  content: "\e9aa";
  color: #239120;
}
.brand-icon-css3:before {
  content: "\e9ab";
  color: #1572b6;
}
.brand-icon-csswizardry:before {
  content: "\e9ac";
  color: #f43059;
}
.brand-icon-curl:before {
  content: "\e9ad";
}
.brand-icon-d3-dot-js:before {
  content: "\e9ae";
  color: #f9a03c;
}
.brand-icon-dailymotion:before {
  content: "\e9af";
  color: #0066dc;
}
.brand-icon-dart:before {
  content: "\e9b0";
  color: #0175c2;
}
.brand-icon-dashlane:before {
  content: "\e9b1";
  color: #007c97;
}
.brand-icon-datacamp:before {
  content: "\e9b2";
  color: #3ac;
}
.brand-icon-dazn:before {
  content: "\e9b3";
  color: #f8f8f5;
}
.brand-icon-dblp:before {
  content: "\e9b4";
  color: #004f9f;
}
.brand-icon-debian:before {
  content: "\e9b5";
  color: #a81d33;
}
.brand-icon-deepin:before {
  content: "\e9b6";
  color: #007cff;
}
.brand-icon-deezer:before {
  content: "\e9b7";
  color: #feaa2d;
}
.brand-icon-delicious:before {
  content: "\e9b8";
  color: #39f;
}
.brand-icon-deliveroo:before {
  content: "\e9b9";
  color: #00ccbc;
}
.brand-icon-dell:before {
  content: "\e9ba";
  color: #007db8;
}
.brand-icon-deno:before {
  content: "\e9bb";
}
.brand-icon-dependabot:before {
  content: "\e9bc";
  color: #025e8c;
}
.brand-icon-designernews:before {
  content: "\e9bd";
  color: #2d72d9;
}
.brand-icon-dev-dot-to:before {
  content: "\e9be";
}
.brand-icon-deviantart:before {
  content: "\e9bf";
  color: #05cc47;
}
.brand-icon-devrant:before {
  content: "\e9c0";
  color: #f99a66;
}
.brand-icon-diaspora:before {
  content: "\e9c1";
}
.brand-icon-digg:before {
  content: "\e9c2";
}
.brand-icon-digitalocean:before {
  content: "\e9c3";
  color: #0080ff;
}
.brand-icon-directus:before {
  content: "\e9c4";
}
.brand-icon-discord:before {
  content: "\e9c5";
  color: #7289da;
}
.brand-icon-discourse:before {
  content: "\e9c6";
}
.brand-icon-discover:before {
  content: "\e9c7";
  color: #ff6000;
}
.brand-icon-disqus:before {
  content: "\e9c8";
  color: #2e9fff;
}
.brand-icon-disroot:before {
  content: "\e9c9";
  color: #50162d;
}
.brand-icon-django:before {
  content: "\e9ca";
}
.brand-icon-dlna:before {
  content: "\e9cb";
  color: #48a842;
}
.brand-icon-docker:before {
  content: "\e9cc";
  color: #1488c6;
}
.brand-icon-docusign:before {
  content: "\e9cd";
  color: #fc2;
}
.brand-icon-dolby:before {
  content: "\e9ce";
}
.brand-icon-dot-net:before {
  content: "\e9cf";
  color: #5c2d91;
}
.brand-icon-draugiem-dot-lv:before {
  content: "\e9d0";
  color: #f60;
}
.brand-icon-dribbble:before {
  content: "\e9d1";
  color: #ea4c89;
}
.brand-icon-drone:before {
  content: "\e9d2";
}
.brand-icon-dropbox:before {
  content: "\e9d3";
  color: #0061ff;
}
.brand-icon-drupal:before {
  content: "\e9d4";
  color: #0678be;
}
.brand-icon-dtube:before {
  content: "\e9d5";
  color: #f00;
}
.brand-icon-duckduckgo:before {
  content: "\e9d6";
  color: #de5833;
}
.brand-icon-dunked:before {
  content: "\e9d7";
  color: #2da9d7;
}
.brand-icon-duolingo:before {
  content: "\e9d8";
  color: #58cc02;
}
.brand-icon-dynatrace:before {
  content: "\e9d9";
  color: #1496ff;
}
.brand-icon-ebay:before {
  content: "\e9da";
  color: #e53238;
}
.brand-icon-eclipseide:before {
  content: "\e9db";
  color: #2c2255;
}
.brand-icon-elastic:before {
  content: "\e9dc";
  color: #005571;
}
.brand-icon-elasticcloud:before {
  content: "\e9dd";
  color: #005571;
}
.brand-icon-elasticsearch:before {
  content: "\e9de";
  color: #005571;
}
.brand-icon-elasticstack:before {
  content: "\e9df";
  color: #005571;
}
.brand-icon-electron:before {
  content: "\e9e0";
  color: #47848f;
}
.brand-icon-elementary:before {
  content: "\e9e1";
  color: #64baff;
}
.brand-icon-eleventy:before {
  content: "\e9e2";
}
.brand-icon-ello:before {
  content: "\e9e3";
}
.brand-icon-elsevier:before {
  content: "\e9e4";
  color: #ff6c00;
}
.brand-icon-ember-dot-js:before {
  content: "\e9e5";
  color: #e04e39;
}
.brand-icon-emlakjet:before {
  content: "\e9e6";
  color: #0ae524;
}
.brand-icon-empirekred:before {
  content: "\e9e7";
  color: #72be50;
}
.brand-icon-envato:before {
  content: "\e9e8";
  color: #81b441;
}
.brand-icon-epel:before {
  content: "\e9e9";
  color: #fc0000;
}
.brand-icon-epicgames:before {
  content: "\e9ea";
}
.brand-icon-epson:before {
  content: "\e9eb";
  color: #039;
}
.brand-icon-esea:before {
  content: "\e9ec";
  color: #0e9648;
}
.brand-icon-eslint:before {
  content: "\e9ed";
  color: #4b32c3;
}
.brand-icon-ethereum:before {
  content: "\e9ee";
}
.brand-icon-etsy:before {
  content: "\e9ef";
  color: #f16521;
}
.brand-icon-eventbrite:before {
  content: "\e9f0";
  color: #f05537;
}
.brand-icon-eventstore:before {
  content: "\e9f1";
  color: #5ab552;
}
.brand-icon-evernote:before {
  content: "\e9f2";
  color: #00a82d;
}
.brand-icon-everplaces:before {
  content: "\e9f3";
  color: #fa4b32;
}
.brand-icon-evry:before {
  content: "\e9f4";
  color: #063a54;
}
.brand-icon-exercism:before {
  content: "\e9f5";
  color: #009cab;
}
.brand-icon-expertsexchange:before {
  content: "\e9f6";
  color: #00aae7;
}
.brand-icon-expo:before {
  content: "\e9f7";
}
.brand-icon-eyeem:before {
  content: "\e9f8";
}
.brand-icon-f-droid:before {
  content: "\e9f9";
  color: #1976d2;
}
.brand-icon-f-secure:before {
  content: "\e9fa";
  color: #00baff;
}
.brand-icon-facebook:before {
  content: "\e9fb";
  color: #1877f2;
}
.brand-icon-faceit:before {
  content: "\e9fc";
  color: #f50;
}
.brand-icon-fandango:before {
  content: "\e9fd";
  color: #ff7300;
}
.brand-icon-favro:before {
  content: "\e9fe";
  color: #512da8;
}
.brand-icon-feathub:before {
  content: "\e9ff";
  color: #9b9b9b;
}
.brand-icon-fedora:before {
  content: "\ea00";
  color: #294172;
}
.brand-icon-feedly:before {
  content: "\ea01";
  color: #2bb24c;
}
.brand-icon-fidoalliance:before {
  content: "\ea02";
  color: #ffbf3b;
}
.brand-icon-figma:before {
  content: "\ea03";
  color: #f24e1e;
}
.brand-icon-filezilla:before {
  content: "\ea04";
  color: #bf0000;
}
.brand-icon-firebase:before {
  content: "\ea05";
  color: #ffca28;
}
.brand-icon-fitbit:before {
  content: "\ea06";
  color: #00b0b9;
}
.brand-icon-fiverr:before {
  content: "\ea07";
  color: #1dbf73;
}
.brand-icon-flask:before {
  content: "\ea08";
}
.brand-icon-flattr:before {
  content: "\ea09";
}
.brand-icon-flickr:before {
  content: "\ea0a";
  color: #0063dc;
}
.brand-icon-flipboard:before {
  content: "\ea0b";
  color: #e12828;
}
.brand-icon-floatplane:before {
  content: "\ea0c";
  color: #00aeef;
}
.brand-icon-flutter:before {
  content: "\ea0d";
  color: #02569b;
}
.brand-icon-fnac:before {
  content: "\ea0e";
  color: #e1a925;
}
.brand-icon-formstack:before {
  content: "\ea0f";
  color: #21b573;
}
.brand-icon-fossa:before {
  content: "\ea10";
  color: #90a1b8;
}
.brand-icon-fossilscm:before {
  content: "\ea11";
  color: #548294;
}
.brand-icon-foursquare:before {
  content: "\ea12";
  color: #f94877;
}
.brand-icon-framer:before {
  content: "\ea13";
  color: #05f;
}
.brand-icon-freebsd:before {
  content: "\ea14";
  color: #ab2b28;
}
.brand-icon-freecodecamp:before {
  content: "\ea15";
  color: #006400;
}
.brand-icon-fujifilm:before {
  content: "\ea16";
  color: #ed1a3a;
}
.brand-icon-fujitsu:before {
  content: "\ea17";
  color: #f00;
}
.brand-icon-furaffinity:before {
  content: "\ea18";
  color: #faaf3a;
}
.brand-icon-furrynetwork:before {
  content: "\ea19";
  color: #2e75b4;
}
.brand-icon-garmin:before {
  content: "\ea1a";
  color: #007cc3;
}
.brand-icon-gatsby:before {
  content: "\ea1b";
  color: #639;
}
.brand-icon-gauges:before {
  content: "\ea1c";
  color: #2fa66a;
}
.brand-icon-genius:before {
  content: "\ea1d";
  color: #ffff64;
}
.brand-icon-gentoo:before {
  content: "\ea1e";
  color: #54487a;
}
.brand-icon-geocaching:before {
  content: "\ea1f";
  color: #00874d;
}
.brand-icon-gerrit:before {
  content: "\ea20";
  color: #eee;
}
.brand-icon-ghost:before {
  content: "\ea21";
  color: #738a94;
}
.brand-icon-gimp:before {
  content: "\ea22";
  color: #5c5543;
}
.brand-icon-git:before {
  content: "\ea23";
  color: #f05032;
}
.brand-icon-gitea:before {
  content: "\ea24";
  color: #609926;
}
.brand-icon-github:before {
  content: "\ea25";
}
.brand-icon-gitlab:before {
  content: "\ea26";
  color: #fca121;
}
.brand-icon-gitpod:before {
  content: "\ea27";
  color: #1aa6e4;
}
.brand-icon-gitter:before {
  content: "\ea28";
  color: #ed1965;
}
.brand-icon-glassdoor:before {
  content: "\ea29";
  color: #0caa41;
}
.brand-icon-glitch:before {
  content: "\ea2a";
  color: #33f;
}
.brand-icon-gmail:before {
  content: "\ea2b";
  color: #d14836;
}
.brand-icon-gnome:before {
  content: "\ea2c";
  color: #4a86cf;
}
.brand-icon-gnu:before {
  content: "\ea2d";
  color: #a42e2b;
}
.brand-icon-gnuicecat:before {
  content: "\ea2e";
  color: #002f5b;
}
.brand-icon-gnuprivacyguard:before {
  content: "\ea2f";
  color: #0093dd;
}
.brand-icon-gnusocial:before {
  content: "\ea30";
  color: #a22430;
}
.brand-icon-go:before {
  content: "\ea31";
  color: #00add8;
}
.brand-icon-godotengine:before {
  content: "\ea32";
  color: #478cbf;
}
.brand-icon-gog-dot-com:before {
  content: "\ea33";
  color: #86328a;
}
.brand-icon-goldenline:before {
  content: "\ea34";
  color: #f1b92b;
}
.brand-icon-goodreads:before {
  content: "\ea35";
  color: #630;
}
.brand-icon-google:before {
  content: "\ea36";
  color: #4285f4;
}
.brand-icon-googleads:before {
  content: "\ea37";
  color: #4285f4;
}
.brand-icon-googleanalytics:before {
  content: "\ea38";
  color: #ffc107;
}
.brand-icon-googlechrome:before {
  content: "\ea39";
  color: #4285f4;
}
.brand-icon-googlecloud:before {
  content: "\ea3a";
  color: #4285f4;
}
.brand-icon-googledrive:before {
  content: "\ea3b";
  color: #4285f4;
}
.brand-icon-googlehangouts:before {
  content: "\ea3c";
  color: #0c9d58;
}
.brand-icon-googlehangoutschat:before {
  content: "\ea3d";
  color: #00897b;
}
.brand-icon-googlekeep:before {
  content: "\ea3e";
  color: #fb0;
}
.brand-icon-googlepay:before {
  content: "\ea3f";
  color: #5f6368;
}
.brand-icon-googleplay:before {
  content: "\ea40";
  color: #607d8b;
}
.brand-icon-googlepodcasts:before {
  content: "\ea41";
  color: #4285f4;
}
.brand-icon-googlescholar:before {
  content: "\ea42";
  color: #4885ed;
}
.brand-icon-googlesearchconsole:before {
  content: "\ea43";
  color: #458cf5;
}
.brand-icon-gov-dot-uk:before {
  content: "\ea44";
  color: #005ea5;
}
.brand-icon-gradle:before {
  content: "\ea45";
}
.brand-icon-grafana:before {
  content: "\ea46";
  color: #f46800;
}
.brand-icon-graphcool:before {
  content: "\ea47";
  color: #27ae60;
}
.brand-icon-graphql:before {
  content: "\ea48";
  color: #e10098;
}
.brand-icon-grav:before {
  content: "\ea49";
}
.brand-icon-gravatar:before {
  content: "\ea4a";
  color: #1e8cbe;
}
.brand-icon-greenkeeper:before {
  content: "\ea4b";
  color: #00c775;
}
.brand-icon-greensock:before {
  content: "\ea4c";
  color: #88ce02;
}
.brand-icon-groovy:before {
  content: "\ea4d";
  color: #4298b8;
}
.brand-icon-groupon:before {
  content: "\ea4e";
  color: #53a318;
}
.brand-icon-gulp:before {
  content: "\ea4f";
  color: #da4648;
}
.brand-icon-gumroad:before {
  content: "\ea50";
  color: #36a9ae;
}
.brand-icon-gumtree:before {
  content: "\ea51";
  color: #72ef36;
}
.brand-icon-gutenberg:before {
  content: "\ea52";
}
.brand-icon-habr:before {
  content: "\ea53";
  color: #77a2b6;
}
.brand-icon-hackaday:before {
  content: "\ea54";
}
.brand-icon-hackerearth:before {
  content: "\ea55";
  color: #323754;
}
.brand-icon-hackerone:before {
  content: "\ea56";
  color: #494649;
}
.brand-icon-hackerrank:before {
  content: "\ea57";
  color: #2ec866;
}
.brand-icon-hackhands:before {
  content: "\ea58";
  color: #00acbd;
}
.brand-icon-hackster:before {
  content: "\ea59";
  color: #1bacf7;
}
.brand-icon-happycow:before {
  content: "\ea5a";
  color: #7c4ec4;
}
.brand-icon-hashnode:before {
  content: "\ea5b";
  color: #2962ff;
}
.brand-icon-haskell:before {
  content: "\ea5c";
  color: #5d4f85;
}
.brand-icon-hatenabookmark:before {
  content: "\ea5d";
  color: #00a4de;
}
.brand-icon-haxe:before {
  content: "\ea5e";
  color: #ea8220;
}
.brand-icon-helm:before {
  content: "\ea5f";
  color: #277a9f;
}
.brand-icon-here:before {
  content: "\ea60";
  color: #48dad0;
}
.brand-icon-heroku:before {
  content: "\ea61";
  color: #430098;
}
.brand-icon-hexo:before {
  content: "\ea62";
  color: #0e83cd;
}
.brand-icon-highly:before {
  content: "\ea63";
  color: #ff3c00;
}
.brand-icon-hipchat:before {
  content: "\ea64";
  color: #0052cc;
}
.brand-icon-hitachi:before {
  content: "\ea65";
  color: #e60027;
}
.brand-icon-hockeyapp:before {
  content: "\ea66";
  color: #009ee1;
}
.brand-icon-homeassistant:before {
  content: "\ea67";
  color: #41bdf5;
}
.brand-icon-homify:before {
  content: "\ea68";
  color: #7dcda3;
}
.brand-icon-hootsuite:before {
  content: "\ea69";
}
.brand-icon-houzz:before {
  content: "\ea6a";
  color: #4dbc15;
}
.brand-icon-hp:before {
  content: "\ea6b";
  color: #0096d6;
}
.brand-icon-html5:before {
  content: "\ea6c";
  color: #e34f26;
}
.brand-icon-htmlacademy:before {
  content: "\ea6d";
  color: #302683;
}
.brand-icon-huawei:before {
  content: "\ea6e";
  color: #f00;
}
.brand-icon-hubspot:before {
  content: "\ea6f";
  color: #ff7a59;
}
.brand-icon-hulu:before {
  content: "\ea70";
  color: #3dbb3d;
}
.brand-icon-humblebundle:before {
  content: "\ea71";
  color: #cc2929;
}
.brand-icon-hurriyetemlak:before {
  content: "\ea72";
  color: #e02826;
}
.brand-icon-hypothesis:before {
  content: "\ea73";
  color: #bd1c2b;
}
.brand-icon-iata:before {
  content: "\ea74";
  color: #004e81;
}
.brand-icon-ibm:before {
  content: "\ea75";
  color: #054ada;
}
.brand-icon-icloud:before {
  content: "\ea76";
  color: #3693f3;
}
.brand-icon-icomoon:before {
  content: "\ea77";
  color: #825794;
}
.brand-icon-icon:before {
  content: "\ea78";
  color: #31b8bb;
}
.brand-icon-iconjar:before {
  content: "\ea79";
  color: #16a5f3;
}
.brand-icon-icq:before {
  content: "\ea7a";
  color: #7ebd00;
}
.brand-icon-ideal:before {
  content: "\ea7b";
  color: #c06;
}
.brand-icon-ifixit:before {
  content: "\ea7c";
  color: #0071ce;
}
.brand-icon-imdb:before {
  content: "\ea7d";
  color: #e6b91e;
}
.brand-icon-imgur:before {
  content: "\ea7e";
  color: #1bb76e;
}
.brand-icon-indeed:before {
  content: "\ea7f";
  color: #2164f3;
}
.brand-icon-influxdb:before {
  content: "\ea80";
  color: #22adf6;
}
.brand-icon-inkscape:before {
  content: "\ea81";
}
.brand-icon-instacart:before {
  content: "\ea82";
  color: #43b02a;
}
.brand-icon-instagram:before {
  content: "\ea83";
  color: #e4405f;
}
.brand-icon-instapaper:before {
  content: "\ea84";
}
.brand-icon-intel:before {
  content: "\ea85";
  color: #0071c5;
}
.brand-icon-intellijidea:before {
  content: "\ea86";
}
.brand-icon-intercom:before {
  content: "\ea87";
  color: #1f8ded;
}
.brand-icon-internetarchive:before {
  content: "\ea88";
}
.brand-icon-internetexplorer:before {
  content: "\ea89";
  color: #0076d6;
}
.brand-icon-invision:before {
  content: "\ea8a";
  color: #f36;
}
.brand-icon-invoiceninja:before {
  content: "\ea8b";
}
.brand-icon-ionic:before {
  content: "\ea8c";
  color: #3880ff;
}
.brand-icon-ios:before {
  content: "\ea8d";
}
.brand-icon-ipfs:before {
  content: "\ea8e";
  color: #65c2cb;
}
.brand-icon-issuu:before {
  content: "\ea8f";
  color: #f36d5d;
}
.brand-icon-itch-dot-io:before {
  content: "\ea90";
  color: #fa5c5c;
}
.brand-icon-itunes:before {
  content: "\ea91";
  color: #fb5bc5;
}
.brand-icon-jabber:before {
  content: "\ea92";
  color: #c00;
}
.brand-icon-java:before {
  content: "\ea93";
  color: #007396;
}
.brand-icon-javascript:before {
  content: "\ea94";
  color: #f7df1e;
}
.brand-icon-jekyll:before {
  content: "\ea95";
  color: #c00;
}
.brand-icon-jenkins:before {
  content: "\ea96";
  color: #d24939;
}
.brand-icon-jest:before {
  content: "\ea97";
  color: #c21325;
}
.brand-icon-jet:before {
  content: "\ea98";
  color: #fbba00;
}
.brand-icon-jetbrains:before {
  content: "\ea99";
}
.brand-icon-jinja:before {
  content: "\ea9a";
  color: #b41717;
}
.brand-icon-jira:before {
  content: "\ea9b";
}
.brand-icon-joomla:before {
  content: "\ea9c";
  color: #5091cd;
}
.brand-icon-jquery:before {
  content: "\ea9d";
  color: #0769ad;
}
.brand-icon-jsdelivr:before {
  content: "\ea9e";
  color: #e84d3d;
}
.brand-icon-jsfiddle:before {
  content: "\ea9f";
  color: #4679a4;
}
.brand-icon-json:before {
  content: "\eaa0";
}
.brand-icon-jupyter:before {
  content: "\eaa1";
  color: #f37626;
}
.brand-icon-justgiving:before {
  content: "\eaa2";
  color: #ad29b6;
}
.brand-icon-kaggle:before {
  content: "\eaa3";
  color: #20beff;
}
.brand-icon-kaios:before {
  content: "\eaa4";
  color: #6f02b5;
}
.brand-icon-kaspersky:before {
  content: "\eaa5";
  color: #009982;
}
.brand-icon-kentico:before {
  content: "\eaa6";
  color: #f05a22;
}
.brand-icon-keras:before {
  content: "\eaa7";
  color: #d00000;
}
.brand-icon-keybase:before {
  content: "\eaa8";
  color: #33a0ff;
}
.brand-icon-keycdn:before {
  content: "\eaa9";
  color: #3686be;
}
.brand-icon-khanacademy:before {
  content: "\eaaa";
  color: #14bf96;
}
.brand-icon-kibana:before {
  content: "\eaab";
  color: #005571;
}
.brand-icon-kickstarter:before {
  content: "\eaac";
  color: #2bde73;
}
.brand-icon-kik:before {
  content: "\eaad";
  color: #82bc23;
}
.brand-icon-kirby:before {
  content: "\eaae";
  color: #ff0100;
}
.brand-icon-klout:before {
  content: "\eaaf";
  color: #e44600;
}
.brand-icon-known:before {
  content: "\eab0";
}
.brand-icon-ko-fi:before {
  content: "\eab1";
  color: #f16061;
}
.brand-icon-kodi:before {
  content: "\eab2";
  color: #17b2e7;
}
.brand-icon-koding:before {
  content: "\eab3";
  color: #00b057;
}
.brand-icon-kotlin:before {
  content: "\eab4";
  color: #0095d5;
}
.brand-icon-krita:before {
  content: "\eab5";
  color: #3babff;
}
.brand-icon-kubernetes:before {
  content: "\eab6";
  color: #326ce5;
}
.brand-icon-laravel:before {
  content: "\eab7";
  color: #ff2d20;
}
.brand-icon-laravelhorizon:before {
  content: "\eab8";
  color: #405263;
}
.brand-icon-laravelnova:before {
  content: "\eab9";
}
.brand-icon-last-dot-fm:before {
  content: "\eaba";
  color: #d51007;
}
.brand-icon-lastpass:before {
  content: "\eabb";
  color: #d32d27;
}
.brand-icon-latex:before {
  content: "\eabc";
  color: #008080;
}
.brand-icon-launchpad:before {
  content: "\eabd";
  color: #f8c300;
}
.brand-icon-leetcode:before {
  content: "\eabe";
  color: #f89f1b;
}
.brand-icon-lenovo:before {
  content: "\eabf";
  color: #e2231a;
}
.brand-icon-letsencrypt:before {
  content: "\eac0";
  color: #003a70;
}
.brand-icon-letterboxd:before {
  content: "\eac1";
  color: #00d735;
}
.brand-icon-lgtm:before {
  content: "\eac2";
  color: #fff;
}
.brand-icon-liberapay:before {
  content: "\eac3";
  color: #f6c915;
}
.brand-icon-librarything:before {
  content: "\eac4";
}
.brand-icon-libreoffice:before {
  content: "\eac5";
  color: #18a303;
}
.brand-icon-line:before {
  content: "\eac6";
  color: #00c300;
}
.brand-icon-linewebtoon:before {
  content: "\eac7";
  color: #00d564;
}
.brand-icon-linkedin:before {
  content: "\eac8";
  color: #0077b5;
}
.brand-icon-linode:before {
  content: "\eac9";
  color: #00a95c;
}
.brand-icon-linux:before {
  content: "\eaca";
  color: #fcc624;
}
.brand-icon-linuxfoundation:before {
  content: "\eacb";
  color: #009bee;
}
.brand-icon-linuxmint:before {
  content: "\eacc";
  color: #87cf3e;
}
.brand-icon-livejournal:before {
  content: "\eacd";
  color: #00b0ea;
}
.brand-icon-livestream:before {
  content: "\eace";
  color: #cf202e;
}
.brand-icon-llvm:before {
  content: "\eacf";
}
.brand-icon-logstash:before {
  content: "\ead0";
  color: #005571;
}
.brand-icon-loop:before {
  content: "\ead1";
  color: #f29400;
}
.brand-icon-lua:before {
  content: "\ead2";
  color: #2c2d72;
}
.brand-icon-lufthansa:before {
  content: "\ead3";
  color: #05164d;
}
.brand-icon-lumen:before {
  content: "\ead4";
  color: #e74430;
}
.brand-icon-lyft:before {
  content: "\ead5";
  color: #ff00bf;
}
.brand-icon-macys:before {
  content: "\ead6";
  color: #e21a2c;
}
.brand-icon-magento:before {
  content: "\ead7";
  color: #ee672f;
}
.brand-icon-magisk:before {
  content: "\ead8";
  color: #00af9c;
}
.brand-icon-mail-dot-ru:before {
  content: "\ead9";
  color: #168de2;
}
.brand-icon-mailchimp:before {
  content: "\eada";
  color: #ffe01b;
}
.brand-icon-makerbot:before {
  content: "\eadb";
  color: #ff1e0d;
}
.brand-icon-manageiq:before {
  content: "\eadc";
  color: #ef2929;
}
.brand-icon-manjaro:before {
  content: "\eadd";
  color: #35bf5c;
}
.brand-icon-mapbox:before {
  content: "\eade";
}
.brand-icon-markdown:before {
  content: "\eadf";
}
.brand-icon-marketo:before {
  content: "\eae0";
  color: #5c4c9f;
}
.brand-icon-mastercard:before {
  content: "\eae1";
  color: #eb001b;
}
.brand-icon-mastodon:before {
  content: "\eae2";
  color: #3088d4;
}
.brand-icon-materialdesign:before {
  content: "\eae3";
  color: #757575;
}
.brand-icon-mathworks:before {
  content: "\eae4";
  color: #0076a8;
}
.brand-icon-matrix:before {
  content: "\eae5";
}
.brand-icon-mattermost:before {
  content: "\eae6";
  color: #0072c6;
}
.brand-icon-matternet:before {
  content: "\eae7";
}
.brand-icon-mcafee:before {
  content: "\eae8";
  color: #c01818;
}
.brand-icon-mdnwebdocs:before {
  content: "\eae9";
}
.brand-icon-mediafire:before {
  content: "\eaea";
  color: #1299f3;
}
.brand-icon-mediatemple:before {
  content: "\eaeb";
}
.brand-icon-medium:before {
  content: "\eaec";
}
.brand-icon-meetup:before {
  content: "\eaed";
  color: #ed1c40;
}
.brand-icon-mega:before {
  content: "\eaee";
  color: #d9272e;
}
.brand-icon-mendeley:before {
  content: "\eaef";
  color: #9d1620;
}
.brand-icon-mercedes:before {
  content: "\eaf0";
}
.brand-icon-messenger:before {
  content: "\eaf1";
  color: #00b2ff;
}
.brand-icon-meteor:before {
  content: "\eaf2";
  color: #de4f4f;
}
.brand-icon-micro-dot-blog:before {
  content: "\eaf3";
  color: #fd8308;
}
.brand-icon-microgenetics:before {
  content: "\eaf4";
  color: #f00;
}
.brand-icon-microsoft:before {
  content: "\eaf5";
  color: #666;
}
.brand-icon-microsoftaccess:before {
  content: "\eaf6";
  color: #ba141a;
}
.brand-icon-microsoftazure:before {
  content: "\eaf7";
  color: #0089d6;
}
.brand-icon-microsoftedge:before {
  content: "\eaf8";
  color: #0078d7;
}
.brand-icon-microsoftexcel:before {
  content: "\eaf9";
  color: #217346;
}
.brand-icon-microsoftoffice:before {
  content: "\eafa";
  color: #e74025;
}
.brand-icon-microsoftonedrive:before {
  content: "\eafb";
  color: #094ab2;
}
.brand-icon-microsoftonenote:before {
  content: "\eafc";
  color: #80397b;
}
.brand-icon-microsoftoutlook:before {
  content: "\eafd";
  color: #0072c6;
}
.brand-icon-microsoftpowerpoint:before {
  content: "\eafe";
  color: #d24726;
}
.brand-icon-microsoftteams:before {
  content: "\eaff";
  color: #6264a7;
}
.brand-icon-microsoftword:before {
  content: "\eb00";
  color: #2b579a;
}
.brand-icon-microstrategy:before {
  content: "\eb01";
  color: #d9232e;
}
.brand-icon-minds:before {
  content: "\eb02";
  color: #fed12f;
}
.brand-icon-minetest:before {
  content: "\eb03";
  color: #53ac56;
}
.brand-icon-minutemailer:before {
  content: "\eb04";
  color: #3abfe6;
}
.brand-icon-mix:before {
  content: "\eb05";
  color: #ff8126;
}
.brand-icon-mixcloud:before {
  content: "\eb06";
  color: #314359;
}
.brand-icon-mixer:before {
  content: "\eb07";
  color: #002050;
}
.brand-icon-mojang:before {
  content: "\eb08";
  color: #db1f29;
}
.brand-icon-monero:before {
  content: "\eb09";
  color: #f60;
}
.brand-icon-mongodb:before {
  content: "\eb0a";
  color: #47a248;
}
.brand-icon-monkeytie:before {
  content: "\eb0b";
  color: #ffc619;
}
.brand-icon-monogram:before {
  content: "\eb0c";
  color: #fdb22a;
}
.brand-icon-monster:before {
  content: "\eb0d";
  color: #6e46ae;
}
.brand-icon-monzo:before {
  content: "\eb0e";
}
.brand-icon-moo:before {
  content: "\eb0f";
  color: #00945e;
}
.brand-icon-mozilla:before {
  content: "\eb10";
}
.brand-icon-mozillafirefox:before {
  content: "\eb11";
  color: #ff7139;
}
.brand-icon-musescore:before {
  content: "\eb12";
  color: #1a70b8;
}
.brand-icon-mxlinux:before {
  content: "\eb13";
}
.brand-icon-myspace:before {
  content: "\eb14";
}
.brand-icon-mysql:before {
  content: "\eb15";
  color: #4479a1;
}
.brand-icon-nativescript:before {
  content: "\eb16";
  color: #3655ff;
}
.brand-icon-nec:before {
  content: "\eb17";
  color: #1414a0;
}
.brand-icon-neo4j:before {
  content: "\eb18";
  color: #008cc1;
}
.brand-icon-netflix:before {
  content: "\eb19";
  color: #e50914;
}
.brand-icon-netlify:before {
  content: "\eb1a";
  color: #00c7b7;
}
.brand-icon-next-dot-js:before {
  content: "\eb1b";
}
.brand-icon-nextcloud:before {
  content: "\eb1c";
  color: #0082c9;
}
.brand-icon-nextdoor:before {
  content: "\eb1d";
  color: #00b246;
}
.brand-icon-nginx:before {
  content: "\eb1e";
  color: #269539;
}
.brand-icon-nim:before {
  content: "\eb1f";
  color: #ffe953;
}
.brand-icon-nintendo:before {
  content: "\eb20";
  color: #8f8f8f;
}
.brand-icon-nintendo3ds:before {
  content: "\eb21";
  color: #d12228;
}
.brand-icon-nintendogamecube:before {
  content: "\eb22";
  color: #6a5fbb;
}
.brand-icon-nintendoswitch:before {
  content: "\eb23";
  color: #e60012;
}
.brand-icon-node-dot-js:before {
  content: "\eb24";
  color: #393;
}
.brand-icon-node-red:before {
  content: "\eb25";
  color: #8f0000;
}
.brand-icon-nodemon:before {
  content: "\eb26";
  color: #76d04b;
}
.brand-icon-nokia:before {
  content: "\eb27";
  color: #124191;
}
.brand-icon-notion:before {
  content: "\eb28";
}
.brand-icon-notist:before {
  content: "\eb29";
}
.brand-icon-npm:before {
  content: "\eb2a";
  color: #cb3837;
}
.brand-icon-nucleo:before {
  content: "\eb2b";
  color: #766dcc;
}
.brand-icon-nuget:before {
  content: "\eb2c";
  color: #004880;
}
.brand-icon-nutanix:before {
  content: "\eb2d";
  color: #024da1;
}
.brand-icon-nuxt-dot-js:before {
  content: "\eb2e";
  color: #00c58e;
}
.brand-icon-nvidia:before {
  content: "\eb2f";
  color: #76b900;
}
.brand-icon-obsstudio:before {
  content: "\eb30";
}
.brand-icon-ocaml:before {
  content: "\eb31";
  color: #ec6813;
}
.brand-icon-octave:before {
  content: "\eb32";
  color: #0790c0;
}
.brand-icon-octopusdeploy:before {
  content: "\eb33";
  color: #2f93e0;
}
.brand-icon-oculus:before {
  content: "\eb34";
}
.brand-icon-odnoklassniki:before {
  content: "\eb35";
  color: #f4731c;
}
.brand-icon-openaccess:before {
  content: "\eb36";
  color: #f68212;
}
.brand-icon-openapiinitiative:before {
  content: "\eb37";
  color: #6ba539;
}
.brand-icon-opencollective:before {
  content: "\eb38";
  color: #7fadf2;
}
.brand-icon-openid:before {
  content: "\eb39";
  color: #f78c40;
}
.brand-icon-opensourceinitiative:before {
  content: "\eb3a";
  color: #3da639;
}
.brand-icon-openssl:before {
  content: "\eb3b";
  color: #721412;
}
.brand-icon-openstreetmap:before {
  content: "\eb3c";
  color: #7ebc6f;
}
.brand-icon-opensuse:before {
  content: "\eb3d";
  color: #73ba25;
}
.brand-icon-openvpn:before {
  content: "\eb3e";
  color: #ea7e20;
}
.brand-icon-opera:before {
  content: "\eb3f";
  color: #ff1b2d;
}
.brand-icon-opsgenie:before {
  content: "\eb40";
}
.brand-icon-oracle:before {
  content: "\eb41";
  color: #f80000;
}
.brand-icon-orcid:before {
  content: "\eb42";
  color: #a6ce39;
}
.brand-icon-origin:before {
  content: "\eb43";
  color: #f56c2d;
}
.brand-icon-osmc:before {
  content: "\eb44";
}
.brand-icon-overcast:before {
  content: "\eb45";
  color: #fc7e0f;
}
.brand-icon-overleaf:before {
  content: "\eb46";
  color: #47a141;
}
.brand-icon-ovh:before {
  content: "\eb47";
  color: #123f6d;
}
.brand-icon-pagekit:before {
  content: "\eb48";
}
.brand-icon-palantir:before {
  content: "\eb49";
}
.brand-icon-paloaltosoftware:before {
  content: "\eb4a";
  color: #83da77;
}
.brand-icon-pandora:before {
  content: "\eb4b";
  color: #005483;
}
.brand-icon-pantheon:before {
  content: "\eb4c";
  color: #efd01b;
}
.brand-icon-parse-dot-ly:before {
  content: "\eb4d";
  color: #5ba745;
}
.brand-icon-pastebin:before {
  content: "\eb4e";
  color: #02456c;
}
.brand-icon-patreon:before {
  content: "\eb4f";
  color: #f96854;
}
.brand-icon-paypal:before {
  content: "\eb50";
  color: #00457c;
}
.brand-icon-peertube:before {
  content: "\eb51";
  color: #f1680d;
}
.brand-icon-periscope:before {
  content: "\eb52";
  color: #40a4c4;
}
.brand-icon-php:before {
  content: "\eb53";
  color: #777bb4;
}
.brand-icon-pi-hole:before {
  content: "\eb54";
  color: #f60d1a;
}
.brand-icon-picarto-dot-tv:before {
  content: "\eb55";
  color: #1da456;
}
.brand-icon-pinboard:before {
  content: "\eb56";
  color: #00f;
}
.brand-icon-pingdom:before {
  content: "\eb57";
  color: #fff000;
}
.brand-icon-pingup:before {
  content: "\eb58";
  color: #00b1ab;
}
.brand-icon-pinterest:before {
  content: "\eb59";
  color: #bd081c;
}
.brand-icon-pivotaltracker:before {
  content: "\eb5a";
  color: #517a9e;
}
.brand-icon-pjsip:before {
  content: "\eb5b";
  color: #f86001;
}
.brand-icon-plangrid:before {
  content: "\eb5c";
  color: #0085de;
}
.brand-icon-player-dot-me:before {
  content: "\eb5d";
  color: #c0379a;
}
.brand-icon-playerfm:before {
  content: "\eb5e";
  color: #c8122a;
}
.brand-icon-playstation:before {
  content: "\eb5f";
  color: #003791;
}
.brand-icon-playstation3:before {
  content: "\eb60";
  color: #003791;
}
.brand-icon-playstation4:before {
  content: "\eb61";
  color: #003791;
}
.brand-icon-plesk:before {
  content: "\eb62";
  color: #52bbe6;
}
.brand-icon-plex:before {
  content: "\eb63";
  color: #e5a00d;
}
.brand-icon-pluralsight:before {
  content: "\eb64";
  color: #f15b2a;
}
.brand-icon-plurk:before {
  content: "\eb65";
  color: #ff574d;
}
.brand-icon-pocket:before {
  content: "\eb66";
  color: #ef3f56;
}
.brand-icon-pocketcasts:before {
  content: "\eb67";
  color: #f43e37;
}
.brand-icon-postgresql:before {
  content: "\eb68";
  color: #336791;
}
.brand-icon-postman:before {
  content: "\eb69";
  color: #ff6c37;
}
.brand-icon-postwoman:before {
  content: "\eb6a";
  color: #50fa7b;
}
.brand-icon-powershell:before {
  content: "\eb6b";
  color: #5391fe;
}
.brand-icon-prestashop:before {
  content: "\eb6c";
  color: #df0067;
}
.brand-icon-prettier:before {
  content: "\eb6d";
  color: #f7b93e;
}
.brand-icon-prismic:before {
  content: "\eb6e";
  color: #484a7a;
}
.brand-icon-probot:before {
  content: "\eb6f";
  color: #00b0d8;
}
.brand-icon-processwire:before {
  content: "\eb70";
  color: #ef145f;
}
.brand-icon-producthunt:before {
  content: "\eb71";
  color: #da552f;
}
.brand-icon-prometheus:before {
  content: "\eb72";
  color: #e6522c;
}
.brand-icon-proto-dot-io:before {
  content: "\eb73";
  color: #34a7c1;
}
.brand-icon-protonmail:before {
  content: "\eb74";
  color: #8b89cc;
}
.brand-icon-proxmox:before {
  content: "\eb75";
  color: #e57000;
}
.brand-icon-publons:before {
  content: "\eb76";
  color: #369;
}
.brand-icon-purescript:before {
  content: "\eb77";
}
.brand-icon-pypi:before {
  content: "\eb78";
  color: #3775a9;
}
.brand-icon-python:before {
  content: "\eb79";
  color: #3776ab;
}
.brand-icon-pytorch:before {
  content: "\eb7a";
  color: #ee4c2c;
}
.brand-icon-pyup:before {
  content: "\eb7b";
  color: #9f55ff;
}
.brand-icon-qemu:before {
  content: "\eb7c";
  color: #f60;
}
.brand-icon-qgis:before {
  content: "\eb7d";
  color: #589632;
}
.brand-icon-qiita:before {
  content: "\eb7e";
  color: #55c500;
}
.brand-icon-qualcomm:before {
  content: "\eb7f";
  color: #3253dc;
}
.brand-icon-quantcast:before {
  content: "\eb80";
}
.brand-icon-quantopian:before {
  content: "\eb81";
  color: #c50000;
}
.brand-icon-quarkus:before {
  content: "\eb82";
  color: #4695eb;
}
.brand-icon-quicktime:before {
  content: "\eb83";
  color: #1c69f0;
}
.brand-icon-quip:before {
  content: "\eb84";
  color: #f27557;
}
.brand-icon-quora:before {
  content: "\eb85";
  color: #b92b27;
}
.brand-icon-qwiklabs:before {
  content: "\eb86";
  color: #f5cd0e;
}
.brand-icon-qzone:before {
  content: "\eb87";
  color: #fece00;
}
.brand-icon-r:before {
  content: "\eb88";
  color: #276dc3;
}
.brand-icon-rabbitmq:before {
  content: "\eb89";
  color: #f60;
}
.brand-icon-radiopublic:before {
  content: "\eb8a";
  color: #ce262f;
}
.brand-icon-rails:before {
  content: "\eb8b";
  color: #c00;
}
.brand-icon-raspberrypi:before {
  content: "\eb8c";
  color: #c51a4a;
}
.brand-icon-react:before {
  content: "\eb8d";
  color: #61dafb;
}
.brand-icon-reactos:before {
  content: "\eb8e";
  color: #08c;
}
.brand-icon-reactrouter:before {
  content: "\eb8f";
  color: #ca4245;
}
.brand-icon-readthedocs:before {
  content: "\eb90";
  color: #8ca1af;
}
.brand-icon-realm:before {
  content: "\eb91";
  color: #39477f;
}
.brand-icon-reason:before {
  content: "\eb92";
  color: #dd4b39;
}
.brand-icon-reasonstudios:before {
  content: "\eb93";
  color: #fff;
}
.brand-icon-redbubble:before {
  content: "\eb94";
  color: #e41321;
}
.brand-icon-reddit:before {
  content: "\eb95";
  color: #ff4500;
}
.brand-icon-redhat:before {
  content: "\eb96";
  color: #e00;
}
.brand-icon-redis:before {
  content: "\eb97";
  color: #d82c20;
}
.brand-icon-redux:before {
  content: "\eb98";
  color: #764abc;
}
.brand-icon-renren:before {
  content: "\eb99";
  color: #217dc6;
}
.brand-icon-repl-dot-it:before {
  content: "\eb9a";
  color: #667881;
}
.brand-icon-researchgate:before {
  content: "\eb9b";
  color: #0cb;
}
.brand-icon-reverbnation:before {
  content: "\eb9c";
  color: #e43526;
}
.brand-icon-riot:before {
  content: "\eb9d";
  color: #368bd6;
}
.brand-icon-ripple:before {
  content: "\eb9e";
  color: #0085c0;
}
.brand-icon-riseup:before {
  content: "\eb9f";
  color: #5e9ee3;
}
.brand-icon-rollup-dot-js:before {
  content: "\eba0";
  color: #ec4a3f;
}
.brand-icon-roots:before {
  content: "\eba1";
  color: #525ddc;
}
.brand-icon-roundcube:before {
  content: "\eba2";
  color: #37beff;
}
.brand-icon-rss:before {
  content: "\eba3";
  color: #ffa500;
}
.brand-icon-rstudio:before {
  content: "\eba4";
  color: #75aadb;
}
.brand-icon-ruby:before {
  content: "\eba5";
  color: #cc342d;
}
.brand-icon-rubygems:before {
  content: "\eba6";
  color: #e9573f;
}
.brand-icon-runkeeper:before {
  content: "\eba7";
  color: #2dc9d7;
}
.brand-icon-rust:before {
  content: "\eba8";
}
.brand-icon-safari:before {
  content: "\eba9";
}
.brand-icon-sahibinden:before {
  content: "\ebaa";
  color: #ffe800;
}
.brand-icon-salesforce:before {
  content: "\ebab";
  color: #00a1e0;
}
.brand-icon-saltstack:before {
  content: "\ebac";
  color: #00eace;
}
.brand-icon-samsung:before {
  content: "\ebad";
  color: #1428a0;
}
.brand-icon-samsungpay:before {
  content: "\ebae";
  color: #1428a0;
}
.brand-icon-sap:before {
  content: "\ebaf";
  color: #008fd3;
}
.brand-icon-sass:before {
  content: "\ebb0";
  color: #c69;
}
.brand-icon-saucelabs:before {
  content: "\ebb1";
  color: #e2231a;
}
.brand-icon-scala:before {
  content: "\ebb2";
  color: #dc322f;
}
.brand-icon-scaleway:before {
  content: "\ebb3";
  color: #4f0599;
}
.brand-icon-scribd:before {
  content: "\ebb4";
  color: #1a7bba;
}
.brand-icon-scrutinizerci:before {
  content: "\ebb5";
  color: #8a9296;
}
.brand-icon-seagate:before {
  content: "\ebb6";
  color: #72be4f;
}
.brand-icon-sega:before {
  content: "\ebb7";
  color: #0089cf;
}
.brand-icon-sellfy:before {
  content: "\ebb8";
  color: #21b352;
}
.brand-icon-semaphoreci:before {
  content: "\ebb9";
  color: #19a974;
}
.brand-icon-sensu:before {
  content: "\ebba";
  color: #89c967;
}
.brand-icon-sentry:before {
  content: "\ebbb";
  color: #fb4226;
}
.brand-icon-serverfault:before {
  content: "\ebbc";
  color: #e7282d;
}
.brand-icon-shazam:before {
  content: "\ebbd";
  color: #08f;
}
.brand-icon-shell:before {
  content: "\ebbe";
  color: #ffd500;
}
.brand-icon-shopify:before {
  content: "\ebbf";
  color: #7ab55c;
}
.brand-icon-showpad:before {
  content: "\ebc0";
  color: #2d2e83;
}
.brand-icon-siemens:before {
  content: "\ebc1";
  color: #099;
}
.brand-icon-signal:before {
  content: "\ebc2";
  color: #2592e9;
}
.brand-icon-simpleicons:before {
  content: "\ebc3";
}
.brand-icon-sinaweibo:before {
  content: "\ebc4";
  color: #e6162d;
}
.brand-icon-sitepoint:before {
  content: "\ebc5";
  color: #258aaf;
}
.brand-icon-sketch:before {
  content: "\ebc6";
  color: #f7b500;
}
.brand-icon-skillshare:before {
  content: "\ebc7";
  color: #17c5cb;
}
.brand-icon-skyliner:before {
  content: "\ebc8";
  color: #2fcea0;
}
.brand-icon-skype:before {
  content: "\ebc9";
  color: #00aff0;
}
.brand-icon-slack:before {
  content: "\ebca";
  color: #4a154b;
}
.brand-icon-slashdot:before {
  content: "\ebcb";
  color: #026664;
}
.brand-icon-slickpic:before {
  content: "\ebcc";
  color: #ff880f;
}
.brand-icon-slides:before {
  content: "\ebcd";
  color: #e4637c;
}
.brand-icon-smashingmagazine:before {
  content: "\ebce";
  color: #e85c33;
}
.brand-icon-smugmug:before {
  content: "\ebcf";
  color: #6db944;
}
.brand-icon-snapchat:before {
  content: "\ebd0";
  color: #fffc00;
}
.brand-icon-snapcraft:before {
  content: "\ebd1";
  color: #82bea0;
}
.brand-icon-snyk:before {
  content: "\ebd2";
  color: #4c4a73;
}
.brand-icon-society6:before {
  content: "\ebd3";
}
.brand-icon-socket-dot-io:before {
  content: "\ebd4";
}
.brand-icon-sogou:before {
  content: "\ebd5";
  color: #fb6022;
}
.brand-icon-solus:before {
  content: "\ebd6";
  color: #5294e2;
}
.brand-icon-sonarcloud:before {
  content: "\ebd7";
  color: #f3702a;
}
.brand-icon-sonarlint:before {
  content: "\ebd8";
  color: #cc2026;
}
.brand-icon-sonarqube:before {
  content: "\ebd9";
  color: #4e9bcd;
}
.brand-icon-sonarsource:before {
  content: "\ebda";
  color: #cb3032;
}
.brand-icon-songkick:before {
  content: "\ebdb";
  color: #f80046;
}
.brand-icon-sonicwall:before {
  content: "\ebdc";
  color: #f60;
}
.brand-icon-sonos:before {
  content: "\ebdd";
}
.brand-icon-soundcloud:before {
  content: "\ebde";
  color: #f30;
}
.brand-icon-sourceengine:before {
  content: "\ebdf";
  color: #f79a10;
}
.brand-icon-sourceforge:before {
  content: "\ebe0";
  color: #535353;
}
.brand-icon-sourcegraph:before {
  content: "\ebe1";
  color: #00b4f2;
}
.brand-icon-spacemacs:before {
  content: "\ebe2";
  color: #9266cc;
}
.brand-icon-spacex:before {
  content: "\ebe3";
  color: #005288;
}
.brand-icon-sparkfun:before {
  content: "\ebe4";
  color: #e53525;
}
.brand-icon-sparkpost:before {
  content: "\ebe5";
  color: #fa6423;
}
.brand-icon-spdx:before {
  content: "\ebe6";
  color: #4398cc;
}
.brand-icon-speakerdeck:before {
  content: "\ebe7";
  color: #396;
}
.brand-icon-spectrum:before {
  content: "\ebe8";
  color: #7b16ff;
}
.brand-icon-spotify:before {
  content: "\ebe9";
  color: #1ed760;
}
.brand-icon-spotlight:before {
  content: "\ebea";
  color: #352a71;
}
.brand-icon-spreaker:before {
  content: "\ebeb";
  color: #f5c300;
}
.brand-icon-spring:before {
  content: "\ebec";
  color: #6db33f;
}
.brand-icon-sprint:before {
  content: "\ebed";
  color: #ffce0a;
}
.brand-icon-square:before {
  content: "\ebee";
}
.brand-icon-squarespace:before {
  content: "\ebef";
}
.brand-icon-stackbit:before {
  content: "\ebf0";
  color: #3eb0fd;
}
.brand-icon-stackexchange:before {
  content: "\ebf1";
  color: #1e5397;
}
.brand-icon-stackoverflow:before {
  content: "\ebf2";
  color: #fe7a16;
}
.brand-icon-stackpath:before {
  content: "\ebf3";
}
.brand-icon-stackshare:before {
  content: "\ebf4";
  color: #0690fa;
}
.brand-icon-stadia:before {
  content: "\ebf5";
  color: #cd2640;
}
.brand-icon-statamic:before {
  content: "\ebf6";
}
.brand-icon-staticman:before {
  content: "\ebf7";
}
.brand-icon-statuspage:before {
  content: "\ebf8";
}
.brand-icon-steam:before {
  content: "\ebf9";
}
.brand-icon-steamworks:before {
  content: "\ebfa";
}
.brand-icon-steem:before {
  content: "\ebfb";
  color: #4ba2f2;
}
.brand-icon-steemit:before {
  content: "\ebfc";
  color: #06d6a9;
}
.brand-icon-steinberg:before {
  content: "\ebfd";
  color: #c90827;
}
.brand-icon-stencyl:before {
  content: "\ebfe";
  color: #8e1c04;
}
.brand-icon-stitcher:before {
  content: "\ebff";
}
.brand-icon-storify:before {
  content: "\ec00";
  color: #3a98d9;
}
.brand-icon-storybook:before {
  content: "\ec01";
  color: #ff4785;
}
.brand-icon-strapi:before {
  content: "\ec02";
  color: #2e7eea;
}
.brand-icon-strava:before {
  content: "\ec03";
  color: #fc4c02;
}
.brand-icon-stripe:before {
  content: "\ec04";
  color: #008cdd;
}
.brand-icon-strongswan:before {
  content: "\ec05";
  color: #e00033;
}
.brand-icon-stubhub:before {
  content: "\ec06";
  color: #003168;
}
.brand-icon-styled-components:before {
  content: "\ec07";
  color: #db7093;
}
.brand-icon-styleshare:before {
  content: "\ec08";
}
.brand-icon-stylus:before {
  content: "\ec09";
}
.brand-icon-sublimetext:before {
  content: "\ec0a";
  color: #ff9800;
}
.brand-icon-subversion:before {
  content: "\ec0b";
  color: #809cc9;
}
.brand-icon-superuser:before {
  content: "\ec0c";
  color: #2eace3;
}
.brand-icon-svelte:before {
  content: "\ec0d";
  color: #ff3e00;
}
.brand-icon-svg:before {
  content: "\ec0e";
  color: #ffb13b;
}
.brand-icon-svgo:before {
  content: "\ec0f";
  color: #14b9ff;
}
.brand-icon-swagger:before {
  content: "\ec10";
  color: #85ea2d;
}
.brand-icon-swarm:before {
  content: "\ec11";
  color: #ffa633;
}
.brand-icon-swift:before {
  content: "\ec12";
  color: #fa7343;
}
.brand-icon-symantec:before {
  content: "\ec13";
  color: #fdb511;
}
.brand-icon-symfony:before {
  content: "\ec14";
}
.brand-icon-synology:before {
  content: "\ec15";
  color: #b6b5b6;
}
.brand-icon-t-mobile:before {
  content: "\ec16";
  color: #e20074;
}
.brand-icon-tableau:before {
  content: "\ec17";
  color: #e97627;
}
.brand-icon-tails:before {
  content: "\ec18";
  color: #56347c;
}
.brand-icon-tailwindcss:before {
  content: "\ec19";
  color: #38b2ac;
}
.brand-icon-tapas:before {
  content: "\ec1a";
  color: #ffce00;
}
.brand-icon-teamviewer:before {
  content: "\ec1b";
  color: #0e8ee9;
}
.brand-icon-ted:before {
  content: "\ec1c";
  color: #e62b1e;
}
.brand-icon-teespring:before {
  content: "\ec1d";
  color: #39ace6;
}
.brand-icon-telegram:before {
  content: "\ec1e";
  color: #2ca5e0;
}
.brand-icon-tencentqq:before {
  content: "\ec1f";
  color: #eb1923;
}
.brand-icon-tencentweibo:before {
  content: "\ec20";
  color: #20b8e5;
}
.brand-icon-tensorflow:before {
  content: "\ec21";
  color: #ff6f00;
}
.brand-icon-teradata:before {
  content: "\ec22";
  color: #f37440;
}
.brand-icon-terraform:before {
  content: "\ec23";
  color: #623ce4;
}
.brand-icon-tesla:before {
  content: "\ec24";
  color: #c00;
}
.brand-icon-themighty:before {
  content: "\ec25";
  color: #d0072a;
}
.brand-icon-themoviedatabase:before {
  content: "\ec26";
  color: #01d277;
}
.brand-icon-tidal:before {
  content: "\ec27";
}
.brand-icon-tiktok:before {
  content: "\ec28";
}
.brand-icon-timescale:before {
  content: "\ec29";
  color: #fdb515;
}
.brand-icon-tinder:before {
  content: "\ec2a";
  color: #ff6b6b;
}
.brand-icon-todoist:before {
  content: "\ec2b";
  color: #e44332;
}
.brand-icon-toggl:before {
  content: "\ec2c";
  color: #e01b22;
}
.brand-icon-topcoder:before {
  content: "\ec2d";
  color: #29a8e0;
}
.brand-icon-toptal:before {
  content: "\ec2e";
  color: #3863a0;
}
.brand-icon-tor:before {
  content: "\ec2f";
  color: #7e4798;
}
.brand-icon-toshiba:before {
  content: "\ec30";
  color: #f00;
}
.brand-icon-trainerroad:before {
  content: "\ec31";
  color: #e12726;
}
.brand-icon-trakt:before {
  content: "\ec32";
  color: #ed1c24;
}
.brand-icon-travisci:before {
  content: "\ec33";
  color: #3eaaaf;
}
.brand-icon-treehouse:before {
  content: "\ec34";
  color: #5fcf80;
}
.brand-icon-trello:before {
  content: "\ec35";
  color: #0079bf;
}
.brand-icon-trendmicro:before {
  content: "\ec36";
  color: #d71921;
}
.brand-icon-tripadvisor:before {
  content: "\ec37";
  color: #00af87;
}
.brand-icon-trulia:before {
  content: "\ec38";
  color: #53b50a;
}
.brand-icon-trustpilot:before {
  content: "\ec39";
  color: #00b67a;
}
.brand-icon-tumblr:before {
  content: "\ec3a";
  color: #36465d;
}
.brand-icon-turkishairlines:before {
  content: "\ec3b";
  color: #c70a0c;
}
.brand-icon-twilio:before {
  content: "\ec3c";
  color: #f22f46;
}
.brand-icon-twitch:before {
  content: "\ec3d";
  color: #6441a4;
}
.brand-icon-twitter:before {
  content: "\ec3e";
  color: #1da1f2;
}
.brand-icon-twoo:before {
  content: "\ec3f";
  color: #ff7102;
}
.brand-icon-typescript:before {
  content: "\ec40";
  color: #007acc;
}
.brand-icon-typo3:before {
  content: "\ec41";
  color: #ff8700;
}
.brand-icon-uber:before {
  content: "\ec42";
}
.brand-icon-ubereats:before {
  content: "\ec43";
  color: #5fb709;
}
.brand-icon-ubisoft:before {
  content: "\ec44";
}
.brand-icon-ublockorigin:before {
  content: "\ec45";
  color: #800000;
}
.brand-icon-ubuntu:before {
  content: "\ec46";
  color: #e95420;
}
.brand-icon-udacity:before {
  content: "\ec47";
  color: #01b3e3;
}
.brand-icon-udemy:before {
  content: "\ec48";
  color: #ec5252;
}
.brand-icon-uikit:before {
  content: "\ec49";
  color: #2396f3;
}
.brand-icon-umbraco:before {
  content: "\ec4a";
  color: #00bec1;
}
.brand-icon-unity:before {
  content: "\ec4b";
}
.brand-icon-unrealengine:before {
  content: "\ec4c";
}
.brand-icon-unsplash:before {
  content: "\ec4d";
}
.brand-icon-untappd:before {
  content: "\ec4e";
  color: #ffc000;
}
.brand-icon-upwork:before {
  content: "\ec4f";
  color: #6fda44;
}
.brand-icon-v:before {
  content: "\ec50";
  color: #5d87bf;
}
.brand-icon-v8:before {
  content: "\ec51";
  color: #4b8bf5;
}
.brand-icon-vagrant:before {
  content: "\ec52";
  color: #1563ff;
}
.brand-icon-valve:before {
  content: "\ec53";
  color: #f74843;
}
.brand-icon-veeam:before {
  content: "\ec54";
  color: #00b336;
}
.brand-icon-venmo:before {
  content: "\ec55";
  color: #3d95ce;
}
.brand-icon-verizon:before {
  content: "\ec56";
  color: #cd040b;
}
.brand-icon-viadeo:before {
  content: "\ec57";
  color: #f88d2d;
}
.brand-icon-viber:before {
  content: "\ec58";
  color: #665cac;
}
.brand-icon-vim:before {
  content: "\ec59";
  color: #019733;
}
.brand-icon-vimeo:before {
  content: "\ec5a";
  color: #1ab7ea;
}
.brand-icon-vine:before {
  content: "\ec5b";
  color: #11b48a;
}
.brand-icon-virb:before {
  content: "\ec5c";
  color: #0093da;
}
.brand-icon-visa:before {
  content: "\ec5d";
  color: #142787;
}
.brand-icon-visualstudio:before {
  content: "\ec5e";
  color: #5c2d91;
}
.brand-icon-visualstudiocode:before {
  content: "\ec5f";
  color: #007acc;
}
.brand-icon-vk:before {
  content: "\ec60";
  color: #4680c2;
}
.brand-icon-vlcmediaplayer:before {
  content: "\ec61";
  color: #f80;
}
.brand-icon-vodafone:before {
  content: "\ec62";
  color: #e60000;
}
.brand-icon-volkswagen:before {
  content: "\ec63";
  color: #151f5d;
}
.brand-icon-vsco:before {
  content: "\ec64";
}
.brand-icon-vue-dot-js:before {
  content: "\ec65";
  color: #4fc08d;
}
.brand-icon-w3c:before {
  content: "\ec66";
  color: #005a9c;
}
.brand-icon-wattpad:before {
  content: "\ec67";
  color: #f68d12;
}
.brand-icon-waze:before {
  content: "\ec68";
  color: #333665;
}
.brand-icon-weasyl:before {
  content: "\ec69";
  color: #900;
}
.brand-icon-webauthn:before {
  content: "\ec6a";
  color: #3423a6;
}
.brand-icon-webcomponents-dot-org:before {
  content: "\ec6b";
  color: #29abe2;
}
.brand-icon-webmin:before {
  content: "\ec6c";
  color: #7da0d0;
}
.brand-icon-webpack:before {
  content: "\ec6d";
  color: #8dd6f9;
}
.brand-icon-webstorm:before {
  content: "\ec6e";
}
.brand-icon-wechat:before {
  content: "\ec6f";
  color: #7bb32e;
}
.brand-icon-whatsapp:before {
  content: "\ec70";
  color: #25d366;
}
.brand-icon-wheniwork:before {
  content: "\ec71";
  color: #51a33d;
}
.brand-icon-whitesource:before {
  content: "\ec72";
  color: #161d4e;
}
.brand-icon-wii:before {
  content: "\ec73";
  color: #8b8b8b;
}
.brand-icon-wiiu:before {
  content: "\ec74";
  color: #8b8b8b;
}
.brand-icon-wikipedia:before {
  content: "\ec75";
}
.brand-icon-windows:before {
  content: "\ec76";
  color: #0078d6;
}
.brand-icon-wire:before {
  content: "\ec77";
}
.brand-icon-wireguard:before {
  content: "\ec78";
  color: #88171a;
}
.brand-icon-wish:before {
  content: "\ec79";
  color: #2fb7ec;
}
.brand-icon-wix:before {
  content: "\ec7a";
  color: #faad4d;
}
.brand-icon-wolfram:before {
  content: "\ec7b";
  color: #d10;
}
.brand-icon-wolframlanguage:before {
  content: "\ec7c";
  color: #d10;
}
.brand-icon-wolframmathematica:before {
  content: "\ec7d";
  color: #d10;
}
.brand-icon-wordpress:before {
  content: "\ec7e";
  color: #21759b;
}
.brand-icon-workplace:before {
  content: "\ec7f";
}
.brand-icon-wpengine:before {
  content: "\ec80";
  color: #40bac8;
}
.brand-icon-write-dot-as:before {
  content: "\ec81";
  color: #5bc4ee;
}
.brand-icon-x-dot-org:before {
  content: "\ec82";
  color: #f28834;
}
.brand-icon-x-pack:before {
  content: "\ec83";
  color: #005571;
}
.brand-icon-xamarin:before {
  content: "\ec84";
  color: #4393d4;
}
.brand-icon-xbox:before {
  content: "\ec85";
  color: #107c10;
}
.brand-icon-xcode:before {
  content: "\ec86";
  color: #1575f9;
}
.brand-icon-xdadevelopers:before {
  content: "\ec87";
  color: #f59812;
}
.brand-icon-xero:before {
  content: "\ec88";
  color: #13b5ea;
}
.brand-icon-xfce:before {
  content: "\ec89";
  color: #2284f2;
}
.brand-icon-xiaomi:before {
  content: "\ec8a";
  color: #fa6709;
}
.brand-icon-xing:before {
  content: "\ec8b";
  color: #006567;
}
.brand-icon-xmpp:before {
  content: "\ec8c";
  color: #002b5c;
}
.brand-icon-xrp:before {
  content: "\ec8d";
  color: #25a768;
}
.brand-icon-xsplit:before {
  content: "\ec8e";
  color: #0095de;
}
.brand-icon-yahoo:before {
  content: "\ec8f";
  color: #6001d2;
}
.brand-icon-yamahacorporation:before {
  content: "\ec90";
  color: #4b1e78;
}
.brand-icon-yamahamotorcorporation:before {
  content: "\ec91";
  color: #e60012;
}
.brand-icon-yammer:before {
  content: "\ec92";
  color: #0072c6;
}
.brand-icon-yandex:before {
  content: "\ec93";
  color: #f00;
}
.brand-icon-yarn:before {
  content: "\ec94";
  color: #2c8ebb;
}
.brand-icon-ycombinator:before {
  content: "\ec95";
  color: #f0652f;
}
.brand-icon-yelp:before {
  content: "\ec96";
  color: #d32323;
}
.brand-icon-youtube:before {
  content: "\ec97";
  color: #f00;
}
.brand-icon-zalando:before {
  content: "\ec98";
  color: #ff6900;
}
.brand-icon-zapier:before {
  content: "\ec99";
  color: #ff4a00;
}
.brand-icon-zeit:before {
  content: "\ec9a";
}
.brand-icon-zend:before {
  content: "\ec9b";
  color: #0679ea;
}
.brand-icon-zendesk:before {
  content: "\ec9c";
}
.brand-icon-zendframework:before {
  content: "\ec9d";
  color: #68b604;
}
.brand-icon-zeromq:before {
  content: "\ec9e";
  color: #df0000;
}
.brand-icon-zerply:before {
  content: "\ec9f";
  color: #9dbc7a;
}
.brand-icon-zhihu:before {
  content: "\eca0";
  color: #0084ff;
}
.brand-icon-zillow:before {
  content: "\eca1";
  color: #0074e4;
}
.brand-icon-zingat:before {
  content: "\eca2";
  color: #009cfb;
}
.brand-icon-zoom:before {
  content: "\eca3";
  color: #2d8cff;
}
.brand-icon-zorin:before {
  content: "\eca4";
  color: #0cc1f3;
}
.brand-icon-zulip:before {
  content: "\eca5";
  color: #52c2af;
}
.brand-icon-web:before {
  content: "\eca7";
}
