/* -------------------------------------
 * Bar container
 * ------------------------------------- */

.css-bar {
    position: relative;
    display: inline-block;
    font-size: 16px;
    border-radius: 50%;
    background-color: transparent;
    margin-bottom: 20px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 80px;
    height: 80px;
    font-size: 18px;
}

.css-bar:after,
.css-bar>img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: light;
    color: #a1a2a3;
}

.css-bar:after {
    content: attr(data-label);
    background-color: #fff;
    z-index: 1;
}

.css-bar>img {
    z-index: 2;
}

.css-bar:after,
.css-bar>img {
    width: 70px;
    height: 70px;
    margin-left: 5px;
    margin-top: 5px;
    line-height: 70px;
}

.css-bar.css-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-50 {
    background-image: linear-gradient(270deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-55 {
    background-image: linear-gradient(288deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-60 {
    background-image: linear-gradient(306deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-65 {
    background-image: linear-gradient(324deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-70 {
    background-image: linear-gradient(342deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-75 {
    background-image: linear-gradient(360deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-80 {
    background-image: linear-gradient(378deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-85 {
    background-image: linear-gradient(396deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-90 {
    background-image: linear-gradient(414deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-95 {
    background-image: linear-gradient(432deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar.css-bar-100 {
    background-image: linear-gradient(450deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-50 {
    background-image: linear-gradient(270deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-55 {
    background-image: linear-gradient(288deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-60 {
    background-image: linear-gradient(306deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-65 {
    background-image: linear-gradient(324deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-70 {
    background-image: linear-gradient(342deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-75 {
    background-image: linear-gradient(360deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-80 {
    background-image: linear-gradient(378deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-85 {
    background-image: linear-gradient(396deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-90 {
    background-image: linear-gradient(414deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-95 {
    background-image: linear-gradient(432deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-primary.css-bar-100 {
    background-image: linear-gradient(450deg, #2c63ff 50%, transparent 50%, transparent), linear-gradient(270deg, #2c63ff 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-50 {
    background-image: linear-gradient(270deg, #26c6da 50%, transparent 50%, transparent), linear-gradient(270deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-55 {
    background-image: linear-gradient(288deg, #26c6da 50%, transparent 50%, transparent), linear-gradient(270deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-60 {
    background-image: linear-gradient(306deg, #26c6da 50%, transparent 50%, transparent), linear-gradient(270deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-65 {
    background-image: linear-gradient(324deg, #26c6da 50%, transparent 50%, transparent), linear-gradient(270deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-70 {
    background-image: linear-gradient(342deg, #26c6da 50%, transparent 50%, transparent), linear-gradient(270deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-75 {
    background-image: linear-gradient(360deg, #26c6da 50%, transparent 50%, transparent), linear-gradient(270deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-80 {
    background-image: linear-gradient(378deg, #26c6da 50%, transparent 50%, transparent), linear-gradient(270deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-85 {
    background-image: linear-gradient(396deg, #26c6da 50%, transparent 50%, transparent), linear-gradient(270deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-90 {
    background-image: linear-gradient(414deg, #26c6da 50%, transparent 50%, transparent), linear-gradient(270deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-95 {
    background-image: linear-gradient(432deg, #26c6da 50%, transparent 50%, transparent), linear-gradient(270deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-success.css-bar-100 {
    background-image: linear-gradient(450deg, #26c6da 50%, transparent 50%, transparent), linear-gradient(270deg, #26c6da 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-50 {
    background-image: linear-gradient(270deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-55 {
    background-image: linear-gradient(288deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-60 {
    background-image: linear-gradient(306deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-65 {
    background-image: linear-gradient(324deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-70 {
    background-image: linear-gradient(342deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-75 {
    background-image: linear-gradient(360deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-80 {
    background-image: linear-gradient(378deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-85 {
    background-image: linear-gradient(396deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-90 {
    background-image: linear-gradient(414deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-95 {
    background-image: linear-gradient(432deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-info.css-bar-100 {
    background-image: linear-gradient(450deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-50 {
    background-image: linear-gradient(270deg, #ffbc34 50%, transparent 50%, transparent), linear-gradient(270deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-55 {
    background-image: linear-gradient(288deg, #ffbc34 50%, transparent 50%, transparent), linear-gradient(270deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-60 {
    background-image: linear-gradient(306deg, #ffbc34 50%, transparent 50%, transparent), linear-gradient(270deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-65 {
    background-image: linear-gradient(324deg, #ffbc34 50%, transparent 50%, transparent), linear-gradient(270deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-70 {
    background-image: linear-gradient(342deg, #ffbc34 50%, transparent 50%, transparent), linear-gradient(270deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-75 {
    background-image: linear-gradient(360deg, #ffbc34 50%, transparent 50%, transparent), linear-gradient(270deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-80 {
    background-image: linear-gradient(378deg, #ffbc34 50%, transparent 50%, transparent), linear-gradient(270deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-85 {
    background-image: linear-gradient(396deg, #ffbc34 50%, transparent 50%, transparent), linear-gradient(270deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-90 {
    background-image: linear-gradient(414deg, #ffbc34 50%, transparent 50%, transparent), linear-gradient(270deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-95 {
    background-image: linear-gradient(432deg, #ffbc34 50%, transparent 50%, transparent), linear-gradient(270deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-warning.css-bar-100 {
    background-image: linear-gradient(450deg, #ffbc34 50%, transparent 50%, transparent), linear-gradient(270deg, #ffbc34 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-50 {
    background-image: linear-gradient(270deg, #fc4b6c 50%, transparent 50%, transparent), linear-gradient(270deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-55 {
    background-image: linear-gradient(288deg, #fc4b6c 50%, transparent 50%, transparent), linear-gradient(270deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-60 {
    background-image: linear-gradient(306deg, #fc4b6c 50%, transparent 50%, transparent), linear-gradient(270deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-65 {
    background-image: linear-gradient(324deg, #fc4b6c 50%, transparent 50%, transparent), linear-gradient(270deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-70 {
    background-image: linear-gradient(342deg, #fc4b6c 50%, transparent 50%, transparent), linear-gradient(270deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-75 {
    background-image: linear-gradient(360deg, #fc4b6c 50%, transparent 50%, transparent), linear-gradient(270deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-80 {
    background-image: linear-gradient(378deg, #fc4b6c 50%, transparent 50%, transparent), linear-gradient(270deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-85 {
    background-image: linear-gradient(396deg, #fc4b6c 50%, transparent 50%, transparent), linear-gradient(270deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-90 {
    background-image: linear-gradient(414deg, #fc4b6c 50%, transparent 50%, transparent), linear-gradient(270deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-95 {
    background-image: linear-gradient(432deg, #fc4b6c 50%, transparent 50%, transparent), linear-gradient(270deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}

.css-bar-danger.css-bar-100 {
    background-image: linear-gradient(450deg, #fc4b6c 50%, transparent 50%, transparent), linear-gradient(270deg, #fc4b6c 50%, #fafafa 50%, #fafafa);
}





/* -- Radial Default -- */

.css-bar-default.css-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-50 {
    background-image: linear-gradient(270deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-55 {
    background-image: linear-gradient(288deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-60 {
    background-image: linear-gradient(306deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-65 {
    background-image: linear-gradient(324deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-70 {
    background-image: linear-gradient(342deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-75 {
    background-image: linear-gradient(360deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-80 {
    background-image: linear-gradient(378deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-85 {
    background-image: linear-gradient(396deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-90 {
    background-image: linear-gradient(414deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-95 {
    background-image: linear-gradient(432deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}

.css-bar-default.css-bar-100 {
    background-image: linear-gradient(450deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #1e88e5 50%, #fafafa 50%, #fafafa);
}






/* -- Radial Pink -- */

.css-bar-pink.css-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-50 {
    background-image: linear-gradient(270deg, #fd5e94 50%, transparent 50%, transparent), linear-gradient(270deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-55 {
    background-image: linear-gradient(288deg, #fd5e94 50%, transparent 50%, transparent), linear-gradient(270deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-60 {
    background-image: linear-gradient(306deg, #fd5e94 50%, transparent 50%, transparent), linear-gradient(270deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-65 {
    background-image: linear-gradient(324deg, #fd5e94 50%, transparent 50%, transparent), linear-gradient(270deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-70 {
    background-image: linear-gradient(342deg, #fd5e94 50%, transparent 50%, transparent), linear-gradient(270deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-75 {
    background-image: linear-gradient(360deg, #fd5e94 50%, transparent 50%, transparent), linear-gradient(270deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-80 {
    background-image: linear-gradient(378deg, #fd5e94 50%, transparent 50%, transparent), linear-gradient(270deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-85 {
    background-image: linear-gradient(396deg, #fd5e94 50%, transparent 50%, transparent), linear-gradient(270deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-90 {
    background-image: linear-gradient(414deg, #fd5e94 50%, transparent 50%, transparent), linear-gradient(270deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-95 {
    background-image: linear-gradient(432deg, #fd5e94 50%, transparent 50%, transparent), linear-gradient(270deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}

.css-bar-pink.css-bar-100 {
    background-image: linear-gradient(450deg, #1e88e5 50%, transparent 50%, transparent), linear-gradient(270deg, #fd5e94 50%, #fafafa 50%, #fafafa);
}






/* -- Radial Purple -- */

.css-bar-purple.css-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-50 {
    background-image: linear-gradient(270deg, #7266ba 50%, transparent 50%, transparent), linear-gradient(270deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-55 {
    background-image: linear-gradient(288deg, #7266ba 50%, transparent 50%, transparent), linear-gradient(270deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-60 {
    background-image: linear-gradient(306deg, #7266ba 50%, transparent 50%, transparent), linear-gradient(270deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-65 {
    background-image: linear-gradient(324deg, #7266ba 50%, transparent 50%, transparent), linear-gradient(270deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-70 {
    background-image: linear-gradient(342deg, #7266ba 50%, transparent 50%, transparent), linear-gradient(270deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-75 {
    background-image: linear-gradient(360deg, #7266ba 50%, transparent 50%, transparent), linear-gradient(270deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-80 {
    background-image: linear-gradient(378deg, #7266ba 50%, transparent 50%, transparent), linear-gradient(270deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-85 {
    background-image: linear-gradient(396deg, #7266ba 50%, transparent 50%, transparent), linear-gradient(270deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-90 {
    background-image: linear-gradient(414deg, #7266ba 50%, transparent 50%, transparent), linear-gradient(270deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-95 {
    background-image: linear-gradient(432deg, #7266ba 50%, transparent 50%, transparent), linear-gradient(270deg, #7266ba 50%, #fafafa 50%, #fafafa);
}

.css-bar-purple.css-bar-100 {
    background-image: linear-gradient(450deg, #7266ba 50%, transparent 50%, transparent), linear-gradient(270deg, #7266ba 50%, #fafafa 50%, #fafafa);
}






/* -- Radial Inverse -- */

.css-bar-inverse.css-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-50 {
    background-image: linear-gradient(270deg, #4c5667 50%, transparent 50%, transparent), linear-gradient(270deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-55 {
    background-image: linear-gradient(288deg, #4c5667 50%, transparent 50%, transparent), linear-gradient(270deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-60 {
    background-image: linear-gradient(306deg, #4c5667 50%, transparent 50%, transparent), linear-gradient(270deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-65 {
    background-image: linear-gradient(324deg, #4c5667 50%, transparent 50%, transparent), linear-gradient(270deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-70 {
    background-image: linear-gradient(342deg, #4c5667 50%, transparent 50%, transparent), linear-gradient(270deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-75 {
    background-image: linear-gradient(360deg, #4c5667 50%, transparent 50%, transparent), linear-gradient(270deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-80 {
    background-image: linear-gradient(378deg, #4c5667 50%, transparent 50%, transparent), linear-gradient(270deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-85 {
    background-image: linear-gradient(396deg, #4c5667 50%, transparent 50%, transparent), linear-gradient(270deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-90 {
    background-image: linear-gradient(414deg, #4c5667 50%, transparent 50%, transparent), linear-gradient(270deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-95 {
    background-image: linear-gradient(432deg, #4c5667 50%, transparent 50%, transparent), linear-gradient(270deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-inverse.css-bar-100 {
    background-image: linear-gradient(450deg, #4c5667 50%, transparent 50%, transparent), linear-gradient(270deg, #4c5667 50%, #fafafa 50%, #fafafa);
}

.css-bar-xlg {
    width: 223px;
    height: 223px;
    font-size: 20px;
}

.css-bar-xlg:after,
.css-bar-xlg>img {
    width: 213px;
    height: 213px;
    margin-left: 5px;
    margin-top: 5px;
    line-height: 30px;
}

.css-bar-lg {
    width: 100px;
    height: 100px;
    font-size: 20px;
    position: relative;
}

.css-bar-lg:after,
.css-bar-lg>img {
    width: 90px;
    height: 90px;
    margin-left: 5px;
    margin-top: 5px;
    line-height: 90px;
}

.css-bar-lg>img {
    width: 70px;
    height: 70px;
    margin-left: 15px;
    margin-top: 15px;
    line-height: 70px;
}

.css-bar>i {
    width: 70px;
    height: 70px;
    background: #fff;
    line-height: 70px;
    position: absolute;
    border-radius: 100%;
    text-align: center;
    margin-left: 5px;
    margin-top: 5px;
    left: 0px;
    right: 0px;
    z-index: 2;
    font-size: 27px;
    color: #a6b7bf;
}

.css-bar-sm {
    width: 50px;
    height: 50px;
    font-size: 12px;
}

.css-bar-sm:after,
.css-bar-sm>img {
    width: 40px;
    height: 40px;
    margin-left: 5px;
    margin-top: 5px;
    line-height: 40px;
}

.css-bar-xs {
    width: 30px;
    height: 30px;
    font-size: 8px;
}

.css-bar-xs:after,
.css-bar-xs>img {
    width: 24px;
    height: 24px;
    margin-left: 3px;
    margin-top: 3px;
    line-height: 21px;
}

.css-bar {
    background-clip: content-box;
}