/*******************
 Breadcrumb and page title
*******************/

.page-breadcrumb {
  padding: 15px 15px 0 15px;

  .page-title {
    margin-bottom: 0px;
  }

  .breadcrumb {
    padding: 0px;
    margin: 0px;
    background: transparent;
    text-transform: uppercase;
    font-size: 12px;

    .breadcrumb-item + .breadcrumb-item::before {
      content: "\e649";
      font-family: themify;
      color: $gray-300;
      font-size: 12px;
    }

    .breadcrumb-item.active {
      color: $muted;
    }
  }
}
