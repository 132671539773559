// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.

@import "custom/_alerts";
@import "custom/animation";
@import "custom/_cards";
@import "custom/_custom-checkbox-toggle";
@import "custom/_flexbox";
@import "custom/_form";
@import "custom/_input-group";
@import "custom/_ngx-datatable";
@import "custom/_survey";
@import "custom/_tabs";
@import "custom/dialogs";
@import "custom/tags";


.mdi-72px {
  font-size: 72px !important;
}

.login-logo {
  width: 200px !important;
}

.navbar-brand img {
  width: 130px;
}

.sidebar-menu-item .sidebar-menu-button {
  padding: 8px 20px;
}

.sidebar-menu-item i {
  font-size: 20px;
}

a {
  color: $themecolor-alt;
  text-decoration: none;
}

.table-clean-wrapper {
  border-radius: 5px;
  border: 1px solid #E2E2E2;
  min-height: 450px;
  background-color: #fff;
}

.table th {
  padding: 8px 12px !important;
}

.table td {
  padding: 8px 12px !important;
}

.form-control,
.custom-select {
  // height: 40px;
}

.plans:hover {
  text-decoration: none;
}

.plans .card:hover {
  border-color: #204FE6;
  background: rgba(32, 79, 230, 0.1);
}

.plans img {
  height: 80px;
}

.card-body {
  padding: 17.5px !important;
}

.gross-title a {
  font-size: 24px;
}

.gross-title em {
  font-size: 12px;
  opacity: .8;
}

.gradient-bg {
  background: #fafecf;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(left, #FFFFFF, #fafecf);
  background: -o-linear-gradient(left, #FFFFFF, #fafecf);
  background: linear-gradient(to right, #FFFFFF, #fafecf);
  /* Chrome 10-25, Safari 5.1-6 */
  font-weight: 500;
  font-style: italic;
}

.gross-table .form-group {
  margin: 0;
}

.gross-table .form-group .form-control-plaintext {
  width: 40%;
  margin-left: auto;
  padding: 0;
}

.step {
  list-style: none;
  margin: 50px auto;
  width: 50%;
  padding: 0;
}

.step .step-item {
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  margin-top: 0;
  min-height: 1rem;
  position: relative;
  text-align: center;
}

.step .step-item:not(:first-child)::before {
  background: #0069d9;
  content: "";
  height: 2px;
  left: -50%;
  position: absolute;
  top: 9px;
  width: 100%;
}

.step .step-item a {
  color: #acb3c2;
  display: inline-block;
  padding: 20px 10px 0;
  text-decoration: none;
}

.step .step-item a::before {
  background: #0069d9;
  border: .1rem solid #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: .9rem;
  left: 50%;
  position: absolute;
  top: .2rem;
  transform: translateX(-50%);
  width: .9rem;
  z-index: 1;
}

.step .step-item.active a::before {
  background: #fff;
  border: .1rem solid #0069d9;
}

.step .step-item.active ~ .step-item::before {
  background: #e7e9ed;
}

.step .step-item.active ~ .step-item a::before {
  background: #e7e9ed;
}


.stepper {
  list-style: none;
  position: relative;
  margin: 3.125rem auto;
}

.stepper::before {
  content: ' ';
  background: #e7e9eded;
  display: inline-block;
  position: absolute;
  left: 1.8125rem;
  width: 0.125rem;
  height: 90%;
  z-index: 1;
}

.stepper li {
  margin: 1.5rem 0rem;
  padding-left: 1.25rem;
}


.stepper li::before {
  content: ' ';
  background: $white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 0.125rem solid #e7e9ed;
  outline: 0.125rem solid $body-bg;
  left: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  z-index: 1;
}

.stepper li.active::before {
  background: $white;
  border: 0.125rem solid #0069d9;
  width: 1rem;
  height: 1rem;
  left: 1.375rem;
}

.stepper li.active.completed::before {
  border: 0.125rem solid #a9c8e8;
  left: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
}

.stepper li.completed::before {
  background-image: url(../../assets/images/check.svg);
  background-repeat: no-repeat;
  background-size: contain;
}


@include media-breakpoint-down(md) {
  .stepper {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &::before {
      display: none;
    }

    li {
      margin: 0 !important;
      padding: 0 0.9375rem !important;
      position: relative !important;
      flex-grow: 1;
      display: flex !important;
      align-items: center;
      justify-content: center;

      &::after {
        content: "";
        position: absolute;
        height: 0.125rem;
        background: #e7e9ed;
        z-index: 0;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
      }

      &:last-child::after {
        width: 100%;
        left: 0%;
      }

      a {
        display: none;
      }
    }
  }
}

.step-img {
  width: 80%;
}

.h100 {
  height: 100%;
}

.icon-text-danger:before {
  color: var(--bs-danger)
}

.icon-text-warning:before {
  color: var(--bs-warning)
}

.icon-text-primary:before {
  color: var(--bs-primary)
}


// for tax table...

td.highlight-warning, tr.highlight-warning {
  color: $warning;
  font-style: italic;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.001), $light-warning);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.001), $light-warning);
}

td.highlight-success, tr.highlight-success {
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.001), $light-success);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.001), $light-success);
}
