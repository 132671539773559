@font-face {
  font-family: 'icomoon-state-map';
  src:  url('fonts/icomoon-state-map.eot?8390cb');
  src:  url('fonts/icomoon-state-map.eot?8390cb#iefix') format('embedded-opentype'),
    url('fonts/icomoon-state-map.ttf?8390cb') format('truetype'),
    url('fonts/icomoon-state-map.woff?8390cb') format('woff'),
    url('fonts/icomoon-state-map.svg?8390cb#icomoon-state-map') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="state-icon-"], [class*=" state-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-state-map' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.state-icon-alabama:before {
  content: "\e900";
  color: #2a2;
}
.state-icon-alaska:before {
  content: "\e901";
  color: #2a2;
}
.state-icon-arizona:before {
  content: "\e902";
  color: #2a2;
}
.state-icon-arkansas:before {
  content: "\e903";
  color: #2a2;
}
.state-icon-california:before {
  content: "\e904";
  color: #2a2;
}
.state-icon-colorado:before {
  content: "\e905";
  color: #2a2;
}
.state-icon-connecticut:before {
  content: "\e907";
  color: #2a2;
}
.state-icon-delaware:before {
  content: "\e908";
  color: #2a2;
}
.state-icon-florida:before {
  content: "\e909";
  color: #2a2;
}
.state-icon-georgia:before {
  content: "\e90b";
  color: #2a2;
}
.state-icon-hawaii:before {
  content: "\e90c";
  color: #2a2;
}
.state-icon-idaho:before {
  content: "\e90d";
  color: #2a2;
}
.state-icon-illinois:before {
  content: "\e90e";
  color: #2a2;
}
.state-icon-indiana:before {
  content: "\e90f";
  color: #2a2;
}
.state-icon-iowa:before {
  content: "\e910";
  color: #2a2;
}
.state-icon-kansas:before {
  content: "\e911";
  color: #2a2;
}
.state-icon-kentucky:before {
  content: "\e912";
  color: #2a2;
}
.state-icon-louisiana:before {
  content: "\e913";
  color: #2a2;
}
.state-icon-maine:before {
  content: "\e914";
  color: #2a2;
}
.state-icon-maryland:before {
  content: "\e915";
  color: #2a2;
}
.state-icon-massachusetts:before {
  content: "\e916";
  color: #2a2;
}
.state-icon-michigan:before {
  content: "\e917";
  color: #2a2;
}
.state-icon-minnesota:before {
  content: "\e918";
  color: #2a2;
}
.state-icon-mississippi:before {
  content: "\e919";
  color: #2a2;
}
.state-icon-missouri:before {
  content: "\e91a";
  color: #2a2;
}
.state-icon-montana:before {
  content: "\e91b";
  color: #2a2;
}
.state-icon-nebraska:before {
  content: "\e91c";
  color: #2a2;
}
.state-icon-nevada:before {
  content: "\e91d";
  color: #2a2;
}
.state-icon-new-hampshire:before {
  content: "\e91e";
  color: #2a2;
}
.state-icon-new-jersey:before {
  content: "\e91f";
  color: #2a2;
}
.state-icon-new-mexico:before {
  content: "\e920";
  color: #2a2;
}
.state-icon-new-york:before {
  content: "\e921";
  color: #2a2;
}
.state-icon-north-carolina:before {
  content: "\e922";
  color: #2a2;
}
.state-icon-north-dakota:before {
  content: "\e923";
  color: #2a2;
}
.state-icon-ohio:before {
  content: "\e924";
  color: #2a2;
}
.state-icon-oklahoma:before {
  content: "\e925";
  color: #2a2;
}
.state-icon-oregon:before {
  content: "\e926";
    color: #2a2;
}
.state-icon-pennsylvania:before {
  content: "\e928";
    color: #2a2;
}
.state-icon-rhode-island:before {
  content: "\e92a";
  color: #2a2;
}
.state-icon-south-carolina:before {
  content: "\e92b";
  color: #2a2;
}
.state-icon-south-dakota:before {
  content: "\e92c";
  color: #2a2;
}
.state-icon-tennessee:before {
  content: "\e92d";
  color: #2a2;
}
.state-icon-texas:before {
  content: "\e92e";
  color: #2a2;
}
.state-icon-utah:before {
  content: "\e92f";
  color: #2a2;
}
.state-icon-vermont:before {
  content: "\e930";
  color: #2a2;
}
.state-icon-virginia:before {
  content: "\e931";
  color: #2a2;
}
.state-icon-washington:before {
  content: "\e932";
  color: #2a2;
}
.state-icon-district-of-columbia:before {
  content: "\e933";
  color: #2a2;
}
.state-icon-west-virginia:before {
  content: "\e934";
  color: #2a2;
}
.state-icon-wisconsin:before {
  content: "\e935";
  color: #2a2;
}
.state-icon-wyoming:before {
  content: "\e936";
  color: #2a2;
}