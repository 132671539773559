@import "../variable";

.tag {

    &.Active,
    &.active,
    &.success {
        background-color: $success;
        color: $white;
        border: none;
        padding: 5px 8px;
        border-radius: 7px;
    }
}